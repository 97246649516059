/* Mixins
--------------------------------------------- */
/*
 * Media query break points (min & max)
 * CalculateRem
 * Font size
 * Clear fix
 * Center block
 * Full width
 * Anchors
 * Buttons
 * Show / hide
 * Columns
 */

/* Media query mixin
// Set breakpoints from abstracts/_breakpoints-sizes.scss:
// $bp__480: 480;
// $bp__600: 600;
// $bp__800: 800;
// $bp__1000: 1000;
// $bp__1200: 1200;
// $bp__1400: 1400;
//
// usage:
//      Min-width
//      @include bp($bp__480) {} use a set breakpoint
//  or  @include bp(960) {} use any custom number
//
//      max_width
//      @include bp-max($bp__480) {} set bp
//  or  @include bp-max(340) {} custom bp
//
// pixels get converted to rems
*/

// base font (1rem)
$bf: 16;
@mixin bp($bp, $min_max:min-width) {
    $em: calc($bp / $bf);
    @media (#{$min_max}: #{$em}em) {
        @content;
    }
}

@mixin bp-max($bp, $min_max:max-width) {
    $bp: $bp - 1;
    $em: calc($bp / $bf);
    @media (#{$min_max}: #{$em}em) {
        @content;
    }
}

@function calculateRem($size) {
    $remSize: calc($size / 16);
    @return #{$remSize}rem;
}

@mixin font-size($size) {
    font-size: $size + px; //Fallback in px
    font-size: calculateRem($size);
}

@mixin clearfix {
    &::after {
        clear: both;
        content: " ";
        display: table;
    }
}

// Center block
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Full width
@mixin fullwidth {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

// Anchors
@mixin link($linkcolor: $color__link, $hovercolor: $color__link_hover, $decoration: '', $decoration_hover: '') {
    color: $linkcolor;
    text-decoration: #{$decoration};
    //&:visited {
    //    color: $linkcolor;
    //}
    &:hover,
    &:active,
    &:focus {
        color: $hovercolor;
        text-decoration: #{$decoration_hover};
    }
}

// Buttons
@mixin button {
    @include font-size(14);
    background-color: $color__bg_button;
    border-color: $color__border_button;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    color: $color__text_button;
    font-weight: 700;
    padding: 1em 2em;
    text-decoration: none;
    transition: background-color .3s;

    &:hover {
        background-color: $color__bg_button_hover;
        border-color: $color__border_button_hover;
        color: $color__bg_button;
        text-decoration: none;
    }

    &:active,
    &:focus {
        border-color: $color__border_button_focus;
    }
}


// show and hide stuff
@mixin hide {
    left: -9999px;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

@mixin show {
    left: auto;
    opacity: 1;
    position: relative;
    z-index: 1;
}

// screen reader text
@mixin visually-hidden {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important; // Many screen reader and browser combinations announce broken words as they would appear visually.
}


// Form Placeholders
@mixin placeholder {
    &.placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-ms-input-placeholder {
        @content;
    }
    .gf_placeholder {
        @content;
    }
}
