/* List styles
--------------------------------------------- */
:where(ul[class]) {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

// user-entered content lists
.entry-content {
    // Lists
    ul:not([class*="blocks-gallery"]):not([class*="wp-block-latest-posts__list"]):not([class*="-dropdown__list"]):not([class*="card-grid"]):not([class*="icon-items"]),
    ol {
        @include font-size(18);
        line-height: 1.667; //30px
        list-style-position: outside;
        margin: 0 0 1.875rem 0.125rem; //0 0 30px 2px
        li {
            margin: 0 0 1.125rem 1rem; //0 0 18px 16px
            padding: 0 0 0 1.25rem; //0 0 0 20px
            padding-inline-start: 1.25rem; //20px
            &::marker {
                color: $color__theme_blue;
            }
            > ul:not([class*="blocks-gallery"]):not([class*="wp-block-latest-posts__list"]),
            > ol {
                margin: 0.75rem 0 0.75rem 0.125rem; //12px 0 12px 2px
            }
            > ul:not([class*="blocks-gallery"]):not([class*="wp-block-latest-posts__list"]) {
                list-style-type: circle;
            }
        }
    }
    ul:not([class*="blocks-gallery"]):not([class*="wp-block-latest-posts__list"]) {
        list-style-type: disc;
        li {

        }
    }
    ol {
        list-style-type: decimal;
        li {
            &::marker {
                @include font($font__main, $medium);
            }
            ul {
                li {
                    &::marker {
                        font-family: revert;
                    }
                }
            }
        }
    }
}
