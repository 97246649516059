// styles for content-type/taxonomy term links.

.post-categories {
    align-items: flex-start;
    column-gap: 1.25rem; //20px
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    row-gap: 0.5rem;
    .post-category {
        align-items: center;
        color: $color__theme_blue;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        @include font($font__heading, $semibold);
        @include font-size(12);
        line-height: 1; //12px
        flex: 0 1 auto;
        position: relative;
        text-transform: uppercase;
        &::after {
            background-color: #ccc;
            content: "";
            display: inline;
            flex: 0 1 auto;
            height: 0.875rem; //14px
            margin: 0 0.75rem; //0 0 0 12px
            position: relative;
            width: 0.063rem; //1px
        }
        &:first-of-type {
            margin-left: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    a {
        @include link($color__theme_blue, $color__theme_blue_dark, none, none);
    }
}
