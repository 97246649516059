// -----------------------------------------------------------------------------
// This file contains all styles related to the header and main site navigation.
// -----------------------------------------------------------------------------

// keep WP admin toolbar fixed on mobile
#wpadminbar {
    @include bp-max($bp__600) {
        position: fixed;
    }
}


// SITE HEADER
.site-header {
    background-color: rgba($color__theme_black, 0);
    left: 0;
    position: fixed;
    top: 0;
    transition-duration: 0.3s;
    transition-property: background-color, border-color, height, opacity, transform, width;
    transition-timing-function: ease;
    min-width: 375px;
    width: 100%;
    will-change: transform;
    z-index: 5;
    &:before {
        background-image: linear-gradient(180deg, #080808 0%, rgba(#080808, 0) 100%);
        content: "";
        height: 6.125rem; //98px
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition-delay: 0s;
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
        width: 100%;
        z-index: -1;
    }
    .admin-bar & {
        top: auto;
    }
    .header-top & {
        &:before {
            opacity: 0.6;
        }
    }
    .header-short & {
        background-color: rgba($color__theme_black, 0.8);
    }
    .js-nav .header-short & {
        background-color: rgba($color__theme_black, 0);
    }
    .header-hide & {
        transform: translate(0, -110%);
    }
}

.header-container.content-container {
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    padding: 0;
    transition-duration: 0.3s;
    transition-property: background-color, border-color, box-shadow, height, transform, width;
    transition-timing-function: ease;
}

.header-branding {
    align-items: center;
    background-color: rgba($color__theme_black, 0);
    display: flex;
    flex: 1 0 100%;
    flex-flow: row wrap;
    min-height: 5rem; //80px
    justify-content: space-between;
    padding: 0 0 0 1.25rem; //0 0 0 20px
    position: relative;
    transition-duration: 0.3s;
    transition-property: background-color, box-shadow, height, transform, width;
    transition-timing-function: ease;
    width: 100%;
    z-index: 2;
    @include bp(375) {
        padding: 0 0 0 5.334vw; //0 0 0 20px/375px
    }
    @include bp(750) {
        padding: 0 2.5rem; //0 40px
    }
}



// Logo, title, description
h3.site-branding {
    @include font($font__main, $semibold);
    @include font-size(12);
    letter-spacing: 0.0425em;
    line-height: 1.42857; //20px
    @include bp($bp__600) {
        @include font-size(14);
    }
    > span.branding-prefix {
        color: $color__theme_blue;
        display: block;
        @include font-size(10);
        letter-spacing: 0.0425em;
        line-height: 1.6667; //20px
        @include bp($bp__600) {
            @include font-size(12);
        }
    }
    a {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.site-description {
    @include visually-hidden;
}


// Transparent header-top variation
.page-template-page-home,
.page-template-page-careers {
    &.header-hide {
        .site-header {
            //background-color: transparent;
            transition-delay: 0.3s, 0.3s, 0.3s, 0.3s, 0s, 0.3s;
            &::before {
                transition-delay: 0.3s;
            }
        }
    }
    &.header-short {
        .site-header {
            //transition-duration: 0s, 0s, 0.3s, 0.3s, 0.3s;
            &:before {
                opacity: 1;
            }
        }
        &.header-top {
            .site-header {
                //transition-duration: 0.3s, 0.3s, 0.3s, 0.3s, 0.3s, 0.3s;
            }
        }
    }
    .page-content {
        margin: 0;
        position: relative;
    }

}
