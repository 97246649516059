/* Media and captions
--------------------------------------------- */

// Responsive Embeds
.video-embed {
    float: left;
    height: 0;
    margin-bottom: 1em;
    padding-top: 56.25%;
    position: relative;
    width: 100%;

    iframe,
    object,
    embed,
    video {
        height: 100%;
        left: 0;
        max-height: 90vh;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.wp-block-image figcaption {
    color: $color__text_caption;
    font-style: italic;
    text-align: left;
}


.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
    display: inline-block;
}

.wp-caption {
    margin-bottom: 1.5em;
    max-width: 100%;

    img[class*="wp-image-"] {
        @include center-block;
    }

    .wp-caption-text {
        margin: 0.8075em 0;
    }
}

.wp-caption-text {
    text-align: center;
}


// responsive aspect ratios using object-fit - with js fallback for IE
.object-fit-wrap {
    position: relative;
    &.compat-object-fit {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        img { // hide image if object fit is not supported - opacity to 0 for the link area
            opacity: 0 !important;
        }
    }
    &.position-top {
        &.compat-object-fit {
            background-position: center top;
        }
        img {
            object-position: top;
        }
    }
    img {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}