// -----------------------------------------------------------------------------
// Button styles
// -----------------------------------------------------------------------------

// BUTTONS
// Firefox input/button line-height bugfix & iOS button style clear
[class*="btn-"],
button[type="submit"],
input[type="submit"],
input[type="button"] {
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

button,
input[type="image"] {
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

a.btn,
a[class*="btn-"],
li.menu-item[class*="btn"] > a,
input[type="image"],
button[type="submit"],
input[type="button"],
input[type="submit"] {
    align-items: center;
    appearance: none !important;
    background-color: $color__bg_button;
    border-color: $color__border_button;
    border-radius: 6.25rem; //100px
    border-style: solid;
    border-width: 0.0625rem; //1px
    box-shadow: inset 0 0 0.9375rem 0 #8C745B; //15px
    color: $color__text_button;
    column-gap: 0.5rem; //8px
    cursor: pointer;
    display: inline-flex;
    flex-flow: row nowrap;
    @include font($font__main, $semibold);
    @include font-size(14);
    height: auto;
    justify-content: center;
    letter-spacing: 0.042857em;
    line-height: normal;
    opacity: 1;
    overflow: hidden;
    padding: 0.75rem 1.875rem; //12px 30px
    position: relative;
    text-align: center;
    text-decoration: none;
    //transform: translate(0, 0);
    transition-property: background-color, border-color, color, opacity, transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    vertical-align: middle;
    width: auto;
    min-width: 7.5rem; //120px
    z-index: 1;
    &:hover,
    &:active,
    &:focus {
        background-color: $color__bg_button_hover;
        border-color: $color__border_button_hover;
        color: $color__text_button_hover;
        outline: none;
        text-decoration: none;
        &::before {
            opacity: 1;
        }
    }
    &[class*="lrg"] {
        align-items: center;
        @include font-size(24);
        display: flex;
        flex-flow: row wrap;
        min-height: 5rem; //80px
        justify-content: center;
        width: 100%;
        max-width: 25rem; //400px
    }
    &[class*="-white"] {
        background-color: #fff;
        color: $color__bg_button;
        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            //background-image: linear-gradient(269deg, #0c54a6 0%, #5bbbe4 52%, #1485c7 100%);
            //border-color: $color__bg_button_hover;
            color: $color__text_button_hover;
        }
    }
    &[class*="-rounded"] {
        border-radius: 3.125rem; //50px
    }
    &[class*="-play"] {
        @include font-size(16);
        min-height: 3.5rem; //56px
        padding: 0.3125rem 0.3125rem 0.3125rem 1rem; //5px 5px 5px 16px
        &::after {
            bottom: 0;
            content: "\ea15";
            display: block;
            flex: 0 1 auto;
            font-family: 'icomoon' !important;
            @include font-size(44);
            font-style: normal;
            font-variant: normal;
            font-weight: $regular;
            line-height: normal;
            opacity: 1;
            position: relative;
            transition-duration: 0.2s;
            transition-property: color, opacity;
            transition-timing-function: ease;
        }
    }
    &[class*="-play"] {
        @include font-size(16);
        min-height: 3.5rem; //56px
        padding: 0.3125rem 0.3125rem 0.3125rem 1rem; //5px 5px 5px 16px
        &::after {
            bottom: 0;
            content: "\ea15";
            display: block;
            flex: 0 1 auto;
            font-family: 'icomoon' !important;
            @include font-size(44);
            font-style: normal;
            font-variant: normal;
            font-weight: $regular;
            line-height: normal;
            opacity: 1;
            position: relative;
            transition-duration: 0.2s;
            transition-property: color, opacity;
            transition-timing-function: ease;
        }
    }
    &[class*="-filled"],
    .is-style-fill & {
        --btn-bg-color: #{$color__bg_button};
        --btn-border-color: #{$color__bg_button};
        --btn-color: #fff;
        --btn-bg-hover-color: #{$color__bg_button_hover};
        --btn-color-hover: #fff;
        background-color: var(--btn-bg-color);
        border-color: var(--btn-border-color);
        border-width: 0.125rem; //2px
        color: var(--btn-color);
        &:hover,
        &:active,
        &:focus {
            background-color: var(--btn-bg-hover-color);
            border-color: var(--btn-bg-hover-color);
            color: var(--btn-color-hover);
        }
    }
    &[class*="-amazon"] {
        box-shadow: none; 
        min-height: 3rem;
        max-width: 18rem;
    }
    .is-style-fill &,
    .is-style-outline & {
        &.has-background {
            border-color: transparent;
            &:hover,
            &:active,
            &:focus {
                border-color: transparent;
            }
        }
    }
    p {
        margin: 0;
    }
    .fa {
        transition-property: background-color, border-color, color;
        transition-duration: 0.2s;
        transition-timing-function: ease;
    }
}


// Gutenberg button block
.wp-block-button {
    &.is-style-outline {
        a.wp-block-button__link {
            background-color: transparent;
            border-color: $color__bg_button;
            color: $color__bg_button;
            &:hover,
            &:active,
            &:focus {
                background-color: $color__bg_button_hover;
                border-color: $color__bg_button_hover;
                color: #fff;
            }
            //&:not(.has-text-color) {
            //	color: $color__bg_button;
            //	&:hover,
            //	&:active,
            //	&:focus {
            //		color: #fff;
            //	}
            //}
            //&:not(.has-background) {
            //	background-color: transparent;
            //	border-color: $color__bg_button;
            //	&:hover,
            //	&:active,
            //	&:focus {
            //		background-color: $color__bg_button_hover;
            //		border-color: $color__bg_button_hover;
            //	}
            //}
        }
    }
    &.is-style-fill {
        a.wp-block-button__link {
            border-width: 0;
            padding: 1.1875rem 1.875rem 1.125rem; //19px 30px 18px
            &:hover,
            &:active,
            &:focus {
            }
            //&:not(.has-text-color) {
            //	color: #fff;
            //	&:hover,
            //	&:active,
            //	&:focus {
            //		color: #fff;
            //	}
            //}
            //&:not(.has-background) {
            //	background-color: $color__bg_button;
            //	&:hover,
            //	&:active,
            //	&:focus {
            //		background-color: $color__bg_button_hover;
            //	}
            //}
        }
    }
    a.wp-block-button__link {
        &.has-background {
            border-color: transparent;
            &:hover,
            &:active,
            &:focus {
                border-color: transparent;
            }
        }
    }
}

button[type="submit"],
input[type="submit"] {
    &:hover,
    &:active,
    &:focus {
    }
}


// background-video play/pause button
a.video-play-pause {
    color: #fff;
    cursor: pointer;
    display: block;
    height: 5rem; //80px
    opacity: 0.66;
    position: absolute;
    right: 0;
    text-indent: -99999px;
    top: 0;
    transition-duration: 0.2s;
    transition-property: color, opacity;
    transition-timing-function: ease;
    width: 5rem; //80px
    &:hover,
    &:active,
    &:focus {
        color: #fff;
    }
    &:hover,
    &:active {
        opacity: 1;
    }
    &::before,
    &::after {
        bottom: 0;
        content: "\ea1c";
        display: block;
        font-family: 'icomoon' !important;
        @include font-size(22);
        font-style: normal;
        font-variant: normal;
        font-weight: $regular;
        height: 1em;
        left: 0.25rem; //4px
        line-height: normal;
        margin: auto;
        opacity: 1;
        right: 0;
        position: absolute;
        text-indent: 0;
        top: 0;
        transition-duration: 0.2s;
        transition-property: color, opacity;
        transition-timing-function: ease;
        width: 1em; //22px
    }
    &::after {
        content: "\e905";
        left: 0;
        opacity: 0;
    }
    &.active {
        &::before {
            opacity: 0;
        }
        &::after {
            opacity: 1;
        }
    }
}





