/* Home page styles
--------------------------------------------- */
// Home Page: Intro
.home-intro {
    align-items: center;
    color: #fff;
    display: flex;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    height: auto;
    min-height: 100vh;
    justify-content: stretch;
    position: absolute;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    width: 100%;
    z-index: 10;
    body:not(.intro-visible) & {
        @include visually-hidden;
    }
    .home-intro-figure {
        animation: blurBg 2s forwards cubic-bezier(0.5, 1, 0.89, 1);
        filter: blur(10px);
        height: 100%;
        left: 0;
        margin: 0;
        overflow: hidden;
        position: absolute;
        transform: scale(1) translate3d(0, 0, 0);
        -webkit-transform-style: preserve-3d;
        top: 0;
        width: 100%;
        max-width: 100%;
        will-change: filter, transform;
        z-index: 1;
        img,
        video {
            height: 100%;
            left: 0;
            object-fit: cover;
            object-position: top;
            position: absolute;
            top: 0;
            transform: scale(1) translate3d(0, 0, 1px);
            width: 100%;
            will-change: transform;
            z-index: 1;
        }
        &.intro-man {
            transform-origin: center bottom;
            z-index: 3;
            img {
                aspect-ratio: 419 / 800;
                bottom: 0;
                height: auto;
                max-height: 88.9%;
                margin: 0 auto;
                object-fit: contain;
                right: 0;
                top: auto;
                //transform: translate3d(0, 0, 1px);
                transform-origin: center bottom;
                width: auto;
                @include bp($bp__800) {
                    bottom: 0;
                    height: 95.238%;
                    max-height: 95.238%;
                }
            }
        }
    }
    @keyframes blurBg {
        100% {
            filter: blur(0);
        }
    }
    > .content-container {
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: stretch;
        flex: 1 0 auto;
        z-index: 3;
    }
    .intro-content {
        color: #fff;
        align-items: center;
        display: flex;
        flex: 1 0 auto;
        flex-flow: column nowrap;
        justify-content: flex-end;
        margin: 0 auto;
        opacity: 0;
        position: relative;
        text-align: center;
        width: 100%;
        .loaded & {
            opacity: 1;
        }
    }
    .intro-copy {
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        flex: 1 0 auto;
        justify-content: center;
        margin: auto 0;
        opacity: 1;
        padding: 1rem 0; //16px 0
        position: relative;
        text-shadow: 0 0.125rem 1.875rem rgba(#000, 0.30); //0 2px 30px
        transform: translate(0, 0);
        transition-delay: 0.25s;
        transition-duration: 0.55s;
        transition-property: opacity, transform;
        transition-timing-function: ease-out;
        max-width: 50rem; //800px
    }

    .intro-text {
        animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
        margin: 0 0 1.75rem; //0 0 28px
        transform: scale(0.9) translate(0, 0);
        position: relative;
        @keyframes scale {
            100% {
                transform: scale(1);
            }
        }
        @keyframes opacity {
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        &::after {
            animation: opacity 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
            bottom: 0;
            content: "…";
            display: block;
            @include font-size(18);
            line-height: 1.667; //30px
            font-style: italic;
            left: 0;
            margin: 0 auto;
            opacity: 0;
            position: absolute;
            right: 0;
            @include bp($bp__600) {
                @include font-size(22);
                line-height: 1.5455; //34px
            }
        }
        p {
            @include font-size(18);
            font-style: italic;
            line-height: 1.667; //30px
            margin: 0 0 1.875rem; //0 0 30px
            @include bp($bp__600) {
                @include font-size(22);
                line-height: 1.5455; //34px
                margin: 0 0 2.125rem; //0 0 34px
            }
        }
    }
    .hide-intro {
        opacity: 0;
    }
    .intro-branding {
        flex: 0 1 auto;
        padding: 1.25rem 0; //20px 0
        img {
            opacity: 0.7;
        }
    }
    h1.branding-heading {
        color: rgba(#99755d, 0.4);
        @include font-size(22);
        line-height: 1.18182; //26px
        min-height: 3.8125rem; //61px
        margin: 0 0 1.25rem; //0 0 20px
        position: relative;
        text-transform: uppercase;
        text-indent: -999999px;
        width: 12.5rem; //200px
        @include bp($bp__600) {
            @include font-size(32);
            line-height: 1.1875; //38px
        }
        .logo-shadows {
            height: auto;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    h3.site-branding {
        color: rgba(#fff, 0.5);
        text-shadow: 0 2px 10px rgba(#000, 0.15);
        > span.branding-prefix {
            color: rgba(#fff, 0.5);
        }
        a {
            color: rgba(#fff, 0.5);
            &:hover,
            &:active,
            &:focus,
            &:focus-within {
                color: $color__link_hover;
                text-decoration: none;
            }
        }

    }
    a.video-play-pause {
        display: none;
    }
}


// Home Page: Hero block
.home-hero-block {
    align-items: stretch;
    color: #fff;
    display: flex;
    flex-flow: column wrap;
    flex: 1 0 auto;
    height: auto;
    min-height: 100vh;
    justify-content: center;
    position: relative;
    width: 100%;
    &::after {
        background-image: linear-gradient(180deg, rgba(#080808, 0.4) 0%, rgba(#080808, 0.4) 85%, #080808 100%);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        //transform: translate3d(0, 0, 0);
        width: 100%;
        z-index: 0;
    }
    .home-hero-figure {
        background-color: $color__theme_black;
        height: 100%;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        //transform: translate3d(0, 0, 0);
        //-webkit-transform-style: preserve-3d;
        top: 0;
        width: 100%;
        max-width: 100%;
        will-change: transform;
        .loaded & {
            opacity: 1;
        }
        img,
        video {
            height: 100%;
            left: 0;
            object-fit: cover;
            object-position: top;
            position: absolute;
            top: 0;
            //transform: translate3d(0, 0, 0);
            width: 100%;
            will-change: transform;
            &.hero-image-mobile {
                display: block;
                @include bp($bp__600) {
                    display: none;
                }
            }
        }
    }
    > .content-container {
        align-items: center;
        display: flex;
        flex: 1 0 auto;
        flex-flow: column nowrap;
        justify-content: space-between;
        z-index: 1;
        @include bp($bp__1200) {
            align-items: flex-start;
        }
    }
    .hero-content {
        color: #fff;
        align-items: center;
        display: flex;
        flex: 1 0 auto;
        flex-flow: column nowrap;
        min-height: 36rem; //576px
        justify-content: space-between;
        margin: 0 auto;
        opacity: 0;
        padding: 5rem 0 0; //80px 0 0
        position: relative;
        text-align: center;
        width: 100%;
        @include bp($bp__1000) {
            align-items: center;
            flex-flow: row nowrap;
            text-align: left;
        }
        @include bp($bp__1000) {
        }
        @include bp($bp__1200) {
            margin: 0;
        }
        .loaded & {
            opacity: 1;
        }
    }
    .hero-copy {
        padding: 0.375rem 0 1.25rem; //6px 0 20px
        @include bp($bp__1000) {
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
    }
    h2.hero-svg {
        height: 90px;
        margin-bottom: 2rem;
        width: 300px;
    }
    h2.hero-heading {
        display: inline-block;
        @include font-size(32);
        line-height: 1.3125; //42px
        opacity: 0;
        position: relative;
        text-shadow: 0 0.125rem 1.25rem rgba(#000, 0.40); //0 2px 20px
        text-transform: uppercase;
        transform: translate(0, 6.25rem); //100px
        transition-delay: 0s;
        transition-duration: 0.55s;
        transition-property: opacity, transform;
        transition-timing-function: ease-out;
        max-width: 37.5rem; //600px
        @include bp($bp__1000) {
            @include font-size(48);
            margin: 0 0 2.5rem; //0 0 40px
        }
        .loaded & {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
    .hero-text {
        display: none;
        opacity: 0;
        padding: 0 0 1.25rem; //0 0 20px
        position: relative;
        text-shadow: 0 0.125rem 1.875rem rgba(#000, 0.30); //0 2px 30px
        transform: translate(0, 6.25rem); //100px
        transition-delay: 0.25s;
        transition-duration: 0.55s;
        transition-property: opacity, transform;
        transition-timing-function: ease-out;
        max-width: 25rem; //400px
        @include bp($bp__600) {
            display: block;
        }
        p {
            @include font-size(16);
            line-height: 1.5; //24px
            margin: 0 0 1.875rem; //0 0 30px
        }
        .loaded & {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
    .hero-buttons {
        [class*="btn"] {
            opacity: 0;
            position: relative;
            transform: translate(0, 150%);
            transition-delay: 0.55s;
            transition-duration: 0.55s;
            transition-property: opacity, transform;
            transition-timing-function: ease-out;
            .loaded & {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    .video-play-pause {
        //display: none;
        bottom: 1rem; //80px
        top: auto;
        z-index: 2;
    }
    .hero-updates {
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 1.1875rem 0 0.875rem; //19px 0 14px
        position: relative;
        width: 100%;
        @include bp($bp__1000) {
            align-items: flex-start;
            flex: 0 1 33.333%;
            padding: 1.1875rem 0 2.5rem; //19px 0 40px
        }
        h4.updates-heading {
            @include font-size(18);
            line-height: 1.4444; //26px
            margin: 0 0 1.25rem; //0 0 20px
            max-width: 18rem; //288px
            @include bp($bp__1000) {
                @include font-size(24);
                line-height: 1.5; //36px
            }
        }
        .updates-form {
            @include bp($bp__1000) {
                margin: 0;
            }
        }
    }
    .hero-footer {
        //border-image-slice: 1;
        //border-image-source: linear-gradient(to right, rgba(#fff, 0.5),  rgba(#fff, 0));
        //border-style: solid;
        //border-width: 0.063rem 0 0; //1px 0 0

        padding: 0.875rem 0; //14px 0
        position: relative;
        width: 100%;
        z-index: 1;
        > .content-container {
            align-items: flex-start;
            display: flex;
            flex: 0 1 auto;
            flex-flow: column nowrap;
            justify-content: center;
            padding: 0 1.25rem; //0 0 0 20px

            @include bp(375) {
                padding: 0 5.334vw; //0 20px/375px
            }
            @include bp($bp__600) {
                align-items: center;
                flex-flow: row nowrap;
            }
            @include bp(750) {
                padding: 0 2.5rem; //0 40px
            }
        }
        .hero-share,
        .hero-lang {
            display: none;
            @include bp($bp__600) {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                flex: 0 1 33.333%;
            }
        }
        .hero-lang {
            justify-content: flex-end;
        }
    }
    .scrollto-link {
        align-items: center;
        display: flex;
        flex: 0 1 auto;
        flex-flow: column wrap;
        justify-content: center;
        @include font-size(12);
        line-height: 1.1667; //14px
        margin: 0 auto;
        text-decoration: none;
        @include bp($bp__600) {
            @include font-size(10);
        }
        &::after {
            color: $color__text_heading;
            content: "\f107"; //chevron-down
            display: block;
            flex: 0 1 auto;
            font-family: icomoon !important;
            @include font-size(12);
            font-weight: normal;
            margin: 0 auto;
            position: relative;
            transform: translate(0, 0);
            @include bp($bp__600) {
                display: none;
            }
        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
        .mobile-hide {
            display: none;
            @include bp($bp__600) {
                display: contents;
            }
        }
        .icon-mouse {
            display: none;
            margin: 1rem auto 0; //16px auto 0
            @include bp($bp__600) {
                display: flex;
            }
        }
    }
}

.icon-mouse {
    align-items: center;
    border-color: #fff;
    border-radius: 60.25rem; //100px
    border-style: solid;
    border-width: 0.0625rem; //1px
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 2rem; //32px
    position: relative;
    width: 1.3125rem; //21px
    &::after {
        background-color: #979797;
        content: "";
        display: block;
        flex: 0 1 auto;
        height: 0.5rem; //8px
        position: relative;
        top: -0.25rem; //-4px
        width: 0.0625rem; //1px
    }
}

.home-about-block {
    background-color: $color__theme_black;
    overflow: hidden;
    padding: 0 0 5rem; //0 0 80px
    position: relative;
    width: 100%;
    .content-container {
        padding: 0 2.5rem; //0 40px
        max-width: 80rem; //1280px
        @include bp(375) {
            padding: 0 10.667vw; //0 40px/375px
        }
        @include bp($bp__1000) {
            padding: 0 5.556vw; //0 80px/1440px
            width: 87.5%; //1120px/1280px
        }
        @include bp(1440) {
            padding: 0 5rem; //0 80px
        }
    }
}

.about-header {
    background-image: linear-gradient(180deg, #080808 0%, #1a1816 88%);
    padding: 7.5rem 0 0; //120px 0 0
    @include bp($bp__800) {
        padding: 8rem 0 0; //128px 0 0
    }
    > .content-container {
        &::before {
            background-image: linear-gradient(rgba(#fff, 0.2),  rgba(#fff, 0));
            content: "";
            display: block;
            height: 38.75rem; //620px
            left: 1.25rem; //20px
            margin: auto 1.25rem auto 0; //auto 20px auto 0
            position: absolute;
            top: 5rem; //80px
            width: 0.063rem; //1px
            max-height: 38.75rem; //620px
            z-index: 1;
            @include bp(375) {
                left: 5.334vw; //0 20px/375px
            }
            @include bp($bp__1000) {
                left: calc(5.556vw - 3.75rem); //80px/1440px - 60px
            }
            @include bp(1440) {
                left: 1.25rem; //20px
            }
        }
    }
    h2.about-heading {
        @include font-size(26);
        line-height: 1.46154; //38px
        max-width: 20rem; //320px
        @include bp($bp__800) {
            @include font-size(36);
            line-height: 1.3334; //48px
        }
    }
}

.home-about-figure {
    min-height: 300px;
    aspect-ratio: 1440 / 606;
    height: 35.83333vw; //516px/1440px
    margin: -3.375rem 0 0; //-54px 0 0
    overflow: hidden;
    position: relative;
    min-width: 100%;
    z-index: 0;
    @include bp($bp__800) {
        margin: 0;
    }
    &::after {
        background-image: url(../noise.png);
        background-repeat: repeat;
        background-size: auto;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    img {
        position: absolute;
        bottom: 0;
        width: 100%;
        &#about-bg-birds {
            bottom: auto;
            top: 0;
            width: 19.1%;
        }
    }
}

.about-content {
    padding: 1.25rem 0; //20px 0
    @include bp($bp__800) {
        padding: 2.5rem 0; //40px 0
    }
}

.about-text {
    @include bp($bp__800) {
        column-count: 2;
        column-gap: 80px;
    }
}


// Home Story Slider
.home-stories-block {
    overflow: hidden;
    padding: 7.5rem 0 2.5rem; //120px 0 40px
    position: relative;
    &::before,
    &::after {
        background-image: linear-gradient(180deg, #0f0f0f 0%, rgba(15,15,15,0.00) 100%);
        content: "";
        display: block;
        height: 5rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
    &::after {
        background-image: linear-gradient(0deg, #0f0f0f 0%, rgba(15,15,15,0.00) 100%);
        bottom: 0;
        top: auto;
    }
    canvas {
        //height: 100% !important;
        z-index: 1;
        @include bp($bp__800) {
            left: -5%;
            min-width: 110%;
            max-width: 110%;
        }
    }
}

.home-story-content {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    z-index: 3;
    @include bp($bp__800) {
        flex-flow: row nowrap;
        align-items: stretch;
    }
}

.home-story-slider {
    flex: 1 0 auto;
    width: 100%;
    @include bp($bp__800) {
        flex: 0 1 calc(100% - 15rem); //-240px
        width: calc(100% - 15rem); //-240px
    }
    .slick-list {
        height: 100%;
        overflow: visible;
    }
    .slick-track {
        align-items: stretch;
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        z-index: 2;
    }
    .slick-slide {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
        @include bp($bp__800) {
            align-items: flex-end;
        }
    }
}

.story-slide-content {
    align-items: flex-start;
    display: flex;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    height: 100%;
    //min-height: 37.5rem; //600px
    justify-content: flex-start;
    padding: 67.73333vw 0 0; //254px/375px 0 0
    @include bp($bp__800) {
        align-items: flex-end;
        min-height: 52.08333vw; //750px/1440px
        padding: 8.875rem 0 0; //142px 0 0
    }
    @include bp(1440) {
        min-height: 46.875rem; //750px
    }
}

.story-graphic {
    //padding: 2.5rem 0; //40px 0 0
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
}

.story-figure {
    height: 100%;
    img {
        max-height: 100%;
        margin: 0 auto;
    }
}

.story-copy {
    //margin: -1.875rem 0 0; //-30px 0 0
    padding: 0 1.25rem; //0 20px
    position: relative;
    @include bp(375) {
        padding: 0 5.334vw; //0 20px/375px
    }
    @include bp($bp__800) {
        padding: 0;
        width: 21.25rem; //340px
    }
    .btn-bordered {
        margin: 0 0 1px 0;
    }
}

h5.story-topic {
    @include font($font__heading, $medium);
    @include font-size(14);
    line-height: 1.28571; //18px
    margin: 0 0 1.25rem; //0 0 20px
    text-shadow: 0 0.125rem 1.25rem rgba(#000, 0.5); //0 2px 20px
    @include bp($bp__800) {
        @include font-size(18);
        line-height: 1.2222; //22px
    }
}

h3.story-title {
    @include font-size(26);
    line-height: 1.46154; //38px
    margin: 0 0 1.25rem; //0 0 20px
    text-shadow: 0 0.125rem 1.25rem rgba(#000, 0.5); //0 2px 20px
    @include bp($bp__800) {
        @include font-size(36);
        line-height: 1.3333; //48px
    }
}

#slider-nav {
    position: relative;
    @include bp-max($bp__800) {
        height: 0;
        visibility: hidden;
    }
    li {
    }
    a {
        color: rgba(#fff, 0.5);
        display: block;
        @include font($font__main, $semibold);
        @include font-size(16);
        line-height: 1.25; //20px
        margin: 2.625rem 0; //42px 0
        padding: 1.25rem 0; //20px 0
        position: relative;
        text-align: right;
        text-decoration: none;
        transition-duration: 0.3s;
        &::before {
            background-image: linear-gradient(to right, rgba(#fff, 0.8),  rgba(#fff, 0.5));
            content: "";
            display: inline-block;
            height: 0.063rem; //1px
            left: 0;
            margin: auto 1.25rem auto 0; //auto 20px auto 0
            position: relative;
            top: -0.375rem; //-6px
            transform: scale(0);
            transform-origin: left center;
            transition-duration: 0.3s;
            transition-property: transform;
            transition-timing-function: ease;
            width: 3.75rem; //60px
        }
        &:hover,
        &:active,
        &:focus {
            color: rgba(#fff, 1);
            text-decoration: none;
            &::before {
                transform: scale(1);
            }
        }
        &.active {
            color: rgba(#fff, 1);
            &::before {
                transform: scale(1);
            }
        }
    }
}

.home-story-footer {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    z-index: 3;
    @include bp($bp__800) {
        visibility: hidden;
    }
    > .content-container {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding-bottom: 2.5rem;
        padding-top: 2.5rem;
    }
    .slider-status {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        flex: 1 0 auto;
        position: relative;
        text-align: center;
        &::before,
        &::after {
            background-color: rgba(#fff, 0.3);
            content: "";
            height: 0.0625rem; //1px
            margin: auto 1.25rem; //auto 20px
            position: relative;
            flex: 1 0 auto;
            width: auto;
        }
    }
}

// Home Lower block
.home-lower-block {
    position: relative;
    text-align: center;
    > .content-container {
        padding-bottom: 5rem; //80px
        padding-top: 10.625rem; //170px
        &::before {
            background-image: linear-gradient(rgba(#fff, 0.2),  rgba(#fff, 0));
            content: "";
            display: block;
            height: 10.625rem; //170px
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 0.063rem; //1px
            z-index: 1;
        }
    }
    .lower-content {
        margin: 0 auto;
        max-width: 50rem; //800px
    }
}

.home-signup {
    padding: 0 0 5rem; //0 0 80px
}

.cannes {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 45px;
    width: 100%;

    #leaves {
        @include bp($bp__1000) {
            margin-right: 2rem;
        }
        max-width: 190px;
    }

    &::before {
        background-image: linear-gradient(to right, rgba(#fff, 0.5),  rgba(#fff, 0));
        content: "";
        display: block;
        height: 0.0625rem; //1px
        left: 0;
        position: absolute;
        top: 56%;
        width: calc(100% + 1.25rem); //+ 20px

        @include bp(375) {
            width: calc(100% + 5.334vw); //+ 20px/375px
        }
        @include bp($bp__1000) {
            top: 53%;
            bottom: auto;
            width: calc(100% + 2.5rem); //+ 40px
        }
    }
}