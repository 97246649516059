// Single post page styles
.single-post,
.single-issue,
.single-publication,
.single-testimonial {
    .site-primary {

        .content-sharing {
            display: flex;

            .social-share-list.addthis_toolbox a.addthis_button_compact span {
                text-transform: none;
                color: #4f6ab2;
            }

            .fa-share2::before {
                display: none;
            }
        }
    }
}

// Post header
.post-header.page-header {

    .post-meta-holder {
        display: flex;

        .post-meta {
            margin-bottom: 0;
        }

        .post-categories {
            margin-bottom: 0;
        }
    }

}

.page-share {
    padding: 0 0 5rem;
}

    // Post footer
.post-footer {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 100%;
}

.more-posts {
    padding: 5rem 0 0; //80px 0 0
    width: 100%;
    .post-list {
    }
}

.more-posts-header {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 0 2.5rem; //0 0 40px
    padding: 0 0 2.25rem; //0 0 36px
    width: 100%;
    h2.more-posts-heading {
        @include font-size(32);
        line-height: 1.25; //40px
    }
    a.more-link {
    }
}