/* Footer styles
--------------------------------------------- */
.site-footer {
    margin: auto 0 0;
    position: relative;
    filter: blur(0);
    transition-duration: 0.3s;
    transition-property: filter;
    transition-timing-function: ease;
    width: 100%;
    z-index: 1;
    .footer-content {
        align-items: flex-start;
        border-color: rgba(#fff, 0.152);
        border-style: solid;
        border-width: 0.063rem 0 0; //1px 0 0
        display: flex;
        flex: 1 0 auto;
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 1.25rem 0 2.25rem; //20px 0 36px
        width: 100%;
        @include bp($bp__800) {
            align-items: center;
            flex-flow: row nowrap;
            justify-content: center;
        }
    }
}

.footer-branding {
    align-items: flex-start;
    display: flex;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 100%;
    @include bp($bp__800) {
        flex: 1 1 33.33%;
        order: 2;
        width: auto;
    }
    .footer-logo {
        flex: 1 0 auto;
        margin: 0 auto 1.25rem; //0 auto 20px
        width: 5.5rem; //88px
        @include bp($bp__800) {
            margin: 0 auto;
        }
        a {
            display: block;
            height: 2.5rem; //40px
            position: relative;
            text-indent: -99999px;
            width: 100%;
        }
        .logo-img {
            bottom: 0;
            display: block;
            height: 100%;
            image-rendering: optimizeQuality;
            margin: auto 0;
            position: absolute;
            top: 0;
            transform: translate3d(0,0,0);
            width: auto;
            @include bp($bp__800) {
                margin: 0;
            }
        }
    }
}

.footer-info {
    flex: 1 0 auto;
    text-align: center;
    width: 100%;
    @include bp($bp__800) {
        flex: 1 1 33%;
        order: 1;
        text-align: left;
    }
}

.footer-copyright {
    @include font($font__main, $semibold);
    @include font-size(12);
    letter-spacing: 0.0425em;
    line-height: 1.3334; //16px
    margin: 0 0 1.25rem; //0 0 20px
    @include bp($bp__800) {
        margin: 0;
    }
}

.footer-links-nav {
    flex: 1 0 auto;
    order: 3;
    text-align: center;
    width: 100%;
    @include bp($bp__800) {
        flex: 1 1 33%;
        text-align: right;
    }
}

.footer-links-menu {
    align-items: flex-start;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0;
    row-gap: 0.625rem; //10px
    width: 100%;
    @include bp($bp__800) {
        justify-content: flex-end;
        width: auto;
    }
    li {
        display: inline-flex;
        flex: 0 1 auto;
        justify-content: flex-start;
        margin: 0 0 0.875rem; //0 0 14px
        padding: 0 0.9375rem 0 0.875rem; //0 15px 0 14px
        position: relative;
        @include bp($bp__800) {
            justify-content: flex-end;
            flex: 0 1 auto;
            margin: 0;
            max-width: none;
        }
        &::after {
            background-color: rgba(#fff, 0.3);
            bottom: 0;
            content: "";
            height: 0.8125rem; //14px
            margin: auto 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 0.063rem; //1px
        }
        &:last-of-type {
            @include bp($bp__800) {
                padding-right: 0;
            }
            &::after {
                display: none;
            }
        }
    }
    a {
        flex: 1 0 auto;
        @include font($font__main, $semibold);
        @include font-size(12);
        letter-spacing: 0.0425em;
        line-height: 1.3334; //16px
        text-decoration: none;
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
}
