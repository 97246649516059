/* Link styles
--------------------------------------------- */
a {
    color: $color__link;
    font-weight: $semibold;
    text-decoration: underline;
    text-underline-offset: 0.1875em; //3px/16px
    transition-duration: 0.2s;
    transition-property: background-color, border-color, color, text-decoration-color, transform;
    transition-timing-function: ease;
    &:hover,
    &:active,
    &:focus {
        color: $color__link_hover;
        text-decoration: underline;
    }
    &::before,
    &::after {
        transition-duration: 0.2s;
        transition-property: background-color, border-color, color, text-decoration-color, transform;
        transition-timing-function: ease;
    }
    // document links
    &[href$=".doc"],
    &[href$=".docx"],
    &[href$= ".pdf"],
    &[href$=".ppt"],
    &[href$=".pptx"],
    &[href$=".xls"],
    &[href$=".xlsx"] {
        @include link($color__text_main, $color__link, none, none);
        &::before {
            display: inline-block;
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'icomoon' !important;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            margin-right: 0.25rem; //4px
            speak: none;
            text-transform: none;
        }
    }
    &[href$=".doc"],
    &[href$=".docx"] {
        &::before {
            color: #2280b6;
            content: " \f1c2";
        }
    }
    &[href$= ".pdf"] {
        &::before {
            color: #b70303;
            content: " \f1c1";
        }
    }
    &[href$=".ppt"],
    &[href$=".pptx"] {
        &::before {
            color: #fab022;
            content: " \f1c4";
        }
    }
    &[href$=".xls"],
    &[href$=".xlsx"] {
        &::before {
            color: #2fc38d;
            content: " \f1c3";
        }
    }
    &.white,
    &[class*="-white"] {
        @include link(#fff, #fff, inherit, inherit);
    }
    &[class*="icon-link"] {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        line-height: normal;
        padding: 0 2.25rem 0 0; //0 36px 0 0
        position: relative;
        transform: translate(0, 0);
        transition-property: color;
        text-decoration: none;
        [class*=" fa-"] {
            color: $color__link;
            flex: 0 1 auto;
            @include font-size(18);
            margin: 0 0.5rem 0 0; //0 8px 0 0
            text-align: center;
            width: 1em; //18px
        }
        &:hover,
        &:active,
        &:focus {
            color: $color__link_hover;
            text-decoration: none;
            [class*=" fa-"] {
                color: $color__link_hover;
                transform: translate(0.1875rem, 0); //3px
            }
        }
    }
    &[class*="share-link"] {
        align-items: center;
        color: $color__text_heading;
        column-gap: 0.625rem; //10px
        display: flex;
        flex: 0 1 auto;
        flex-flow: row nowrap;
        @include font($font__main, $semibold);
        @include font-size(14);
        justify-content: flex-start;
        line-height: 1.42857rem; //20px
        position: relative;
        transform: translate(0, 0);
        text-decoration: none;
        &::after {
            color: $color__text_heading;
            content: "\ea82"; //share2
            flex: 0 1 auto;
            font-family: icomoon !important;
            @include font-size(14);
            font-weight: normal;
            line-height: 1.42857rem; //20px
            position: relative;
            transform: translate(0, 0);
        }
        &:hover,
        &:active,
        &:focus {
            color: $color__text_heading;
            text-decoration: none;
            &::after {
                color: $color__link_hover;
            }
        }
    }
    &[class*="lang-link"] {
        align-items: center;
        color: $color__text_heading;
        column-gap: 0.625rem; //10px
        display: flex;
        flex: 0 1 auto;
        flex-flow: row nowrap;
        @include font($font__main, $semibold);
        @include font-size(14);
        justify-content: center;
        line-height: 1.42857rem; //20px
        position: relative;
        transform: translate(0, 0);
        text-decoration: none;
        &::after {
            color: $color__text_heading;
            content: "\e9c9"; //globe
            flex: 0 1 auto;
            font-family: icomoon !important;
            @include font-size(14);
            font-weight: normal;
            line-height: 1.42857rem; //20px
            position: relative;
            transform: translate(0, 0);
        }
        &:hover,
        &:active,
        &:focus {
            color: $color__text_heading;
            text-decoration: none;
            &::after {
                color: $color__link_hover;
            }
        }
    }
    &[class*="more-link"] {
        color: $color__text_heading;
        display: inline-block;
        @include font($font__main, $semibold);
        @include font-size(16);
        padding: 0;
        position: relative;
        transform: translate(0, 0);
        transition-property: color, background-color, transform;
        text-decoration: none;
        &::after {
            background-color: rgba($color__text_heading, 1);
            bottom: 0.125rem; //2px
            content: "";
            display: block;
            height: 0.063rem; //1px
            left: 0;
            position: absolute;
            transform: translate(0, 0);
            transition-duration: 0.2s;
            transition-property: background-color, transform;
            transition-timing-function: ease;
            width: 100%;
        }
        &:hover,
        &:active,
        &:focus {
            color: $color__link_hover;
            text-decoration: none;
            &::after {
                background-color: $color__link_hover;
                transform: translate(0, -0.125rem);
            }
        }
        &[class*="-white"] {
            @include link(#fff, #fff, none, none);
            &::before,
            &::after {
                background-color: #fff;
            }
        }
    }
    &[class*="arrow-link"] {
        align-items: center;
        color: $color__text_heading;
        display: inline-flex;
        flex-flow: row nowrap;
        @include font-size(16);
        justify-content: flex-start;
        line-height: 1.4375rem; //23px
        position: relative;
        transform: translate(0, 0);
        transition-property: color, transform;
        text-decoration: none;
        &::after {
            color: $color__text_heading;
            content: "\ea3c"; //arrow-right2
            display: inline-block;
            font-family: icomoon !important;
            @include font-size(14);
            font-weight: normal;
            line-height: 1.4375rem; //23px
            margin: 0 0 0 0.8125rem; //0 0 0 13px
            position: relative;
            transform: translate(0, 0);
            transition-duration: 0.2s;
            transition-property: color, transform;
            transition-timing-function: ease;
        }
        &:hover,
        &:active,
        &:focus {
            color: $color__link_hover;
            text-decoration: none;
            &::after {
                color: $color__link_hover;
                transform: translate(0.1875rem, 0); //3px
            }
        }
        &[class*="-back"] {
            padding: 0 0 0 1.75rem; //0 0 0 28px
            &::after {
                left: 0;
                right: auto;
                top: 0.125rem; //2px
                transform: rotate(180deg) translate(0, 0);
            }
            &:hover,
            &:active,
            &:focus {
                &::after {
                    transform: rotate(180deg) translate(0.1875rem, 0); //3px
                }
            }
        }
        &[class*="-icon"] {
            &:hover,
            &:active,
            &:focus {
                &::after {
                    transform: translate(0.1875rem, 0); //3px
                }
            }
        }
    }
    &[class*="download-link"] {
        align-items: center;
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        position: relative;
        &::after {
            color: $color__text_heading;
            content: "\e960"; //download
            display: inline-block;
            font-family: icomoon !important;
            font-weight: normal;
            line-height: 1;
            margin: 0 0 0 0.5rem; //0 0 0 8px
            position: relative;
            transform: translate(0, 0);
            transition-duration: 0.2s;
            transition-property: color, transform;
            transition-timing-function: ease;
        }
        &:hover,
        &:active,
        &:focus {
            color: $color__link_hover;
            text-decoration: none;
            &::after {
                color: $color__link_hover;
            }
        }
    }
    &.no-underline {
        text-decoration: none;
    }
    &[class*="external"] {
        align-items: center;
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        position: relative;
        transform: translate(0, 0);
        //transition-duration: 0.2s;
        //transition-property: color, transform;
        //transition-timing-function: ease;
        &::after {
            color: $color__theme_blue;
            content: "\ea7e"; //new-tab
            display: inline;
            /* use !important to prevent issues with browser extensions that change fonts */
            font-family: 'icomoon' !important;
            //@include font-size(12);
            font-size: 0.75em; //12px
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            margin: 0 0 0 0.75rem; //0 0 0 12px
            text-transform: none;
            //transition-duration: 0.2s;
            //transition-property: color, transform;
            //transition-timing-function: ease;
        }
        &:hover,
        &:active,
        &:focus {
            //color: $color__link_hover;
            text-decoration: none;
            &::after {
                //color: $color__link_hover;
            }
        }
    }
    &[class*="styled-link"] {
        color: $color__text_heading;
        display: inline-block;
        @include font($font__main, $semibold);
        @include font-size(14);
        line-height: 1.5; //214px
        padding: 0.6875rem 0 0.8125rem; //11px 0 13px
        position: relative;
        transform: translate(0, 0);
        transition-property: color, background-color, transform;
        text-decoration: none;
        &::after {
            background-color: $color__text_heading;
            bottom: 0;
            content: "";
            display: block;
            height: 0.125rem; //2px
            left: 0;
            position: absolute;
            transform: translate(0, 0);
            transition-duration: 0.2s;
            transition-property: background-color, transform;
            transition-timing-function: ease;
            width: 100%;
        }
        &:hover,
        &:active,
        &:focus {
            color: $color__link_hover;
            text-decoration: none;
            &::after {
                background-color: $color__link_hover;
                transform: translate(0, -0.25rem);
            }
        }
        &[class*="-white"] {
            @include link(#fff, #fff, none, none);
            &::before,
            &::after {
                background-color: #fff;
            }
        }
    }
    &[class*="video-play-link"] {
        align-items: center;
        color: $color__text_main;
        display: inline-flex;
        @include font($font__main, $bold);
        @include font-size(16);
        justify-content: flex-start;
        line-height: 1; //16px
        position: relative;
        transform: translate(0, 0);
        transition-property: color;
        text-decoration: none;
        @include bp($bp__800) {
            @include font-size(18);
        }
        &::before {
            content: "\f04b";
            display: block;
            font-family: icomoon;
            @include font-size(50);
            font-weight: normal;
            line-height: inherit;
            padding: 0 1.25rem 0 0; //0 20px 0 0
            position: relative;
            transform: translate(0, 0);
            transition-duration: 0.2s;
            transition-property: color;
            transition-timing-function: ease;
        }
        &:hover,
        &:active,
        &:focus {
            &::after {
                opacity: 1;
                transform: translate(0.375rem, 0); //6px
            }
        }
    }
    &[class*="clear-link"] {
        align-items: center;
        color: $color__theme_gray_dark;
        display: inline-flex;
        @include font($font__main, $bold);
        @include font-size(14);
        line-height: normal;
        padding: 0.3125rem 0 0.375rem; //5px 0 6px
        position: relative;
        transform: translate(0, 0);
        text-decoration: none;
        &::before {
            color: $color__theme_gray_dark;
            content: "\f00d";
            display: inline;
            font-family: icomoon;
            @include font-size(9);
            font-weight: normal;
            justify-content: flex-start;
            line-height: inherit;
            padding: 0 0.5rem 0 0; //0 8px 0 0
            position: relative;
            transform: translate(0, 0);
            transition-duration: 0.2s;
            transition-property: color;
            transition-timing-function: ease;
        }
        &::after {
            background-color: #ccc;
            bottom: 0;
            content: "";
            display: block;
            height: 0.063rem; //1px
            left: 0;
            position: absolute;
            transition-duration: 0.2s;
            transition-property: background-color;
            transition-timing-function: ease;
            width: 100%;
        }
        &:hover,
        &:active,
        &:focus {
            color: $color__link_hover;
            &::before {
                color: $color__link_hover;
            }
            &::after {
                background-color: $color__link_hover;
            }
        }
    }
}

// External links
//.external:not(.no-icon)::after {
//	content: "\f08e";
//	font-family: icomoon;
//	margin-left: .5em;
//	vertical-align: middle;
//}
