// Site Layout & Container styles

html {
    height: 100%;
    min-height: 50rem; //800px
}

html body {
    background-color: $color__theme_black;
    //font-feature-settings: "liga", "kern";
    height: 100%;
    margin: 0 auto;
    overscroll-behavior-y: none;
    //overflow-x: hidden;
    min-width: 375px;
    &.intro-visible {
        height: 100%;
        overflow: hidden;
    }
}

body {
    --scrollbar: 15px; /* default if Javascript is disabled */
}

.content-container {
    margin: 0 auto;
    padding: 0 1.25rem; //0 20px
    position: relative;
    width: 100%;
    max-width: 90rem; //1440px
    @include bp(375) {
        padding: 0 5.334vw; //0 20px/375px
    }
    @include bp($bp__1000) {
        padding: 0 5.556vw; //0 80px/1440px
    }
    @include bp(1440) {
        padding: 0 5rem; //0 80px
    }
}

// 'Skip to main content' link
.skip-link {
    &.focusable:active,
    &.focusable:focus {
        @include bp($bp__1000) {
            margin-left: 2.5rem; //40px
        }
    }
}

// Main layout
#site-wrap {
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100%;
    justify-content: flex-start;
    overflow-x: hidden;
    width: 100%;
    .intro-visible & {
        height: 100%;
        overflow-y: hidden;
    }
}

.page-content {
    align-items: stretch;
    backface-visibility: hidden;
    display: flex;
    filter: blur(0);
    flex: 1 0 auto;
    flex-flow: column nowrap;
    justify-content: flex-start;
    transition-duration: 0.3s;
    transition-property: filter;
    transition-timing-function: ease;
    width: 100%;
    //z-index: 1;
    .intro-visible & {
        overflow-y: scroll;
    }
}

.page-body {
    background-color: $color__theme_black;
    align-items: stretch;
    display: flex;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    &::after {
        background-image: url(../noise.png);
        background-repeat: repeat;
        background-size: auto;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

// Interior page site-primary content
.site-primary {
    flex: 1 0 auto;
    padding: 3.75rem 0; //60px 0
    position: relative;
    width: 100%;
    max-width: 56.25rem; //900px
    min-width: 20.9375rem; //335px
    @include bp($bp__1000) {
        padding: 6.875rem 0 5rem; //120px 0 80px
    }
}

.site-main {
    flex: 1 0 auto;
    width: 100%;
    //overflow-x: hidden;
}

// Basic Page layout
.content-area {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 1;
    &::before {
        background-image: linear-gradient(rgba(#fff, 0.2),  rgba(#fff, 0));
        content: "";
        display: block;
        height: 38.75rem; //620px
        left: 0.625rem; //10px
        margin: auto 1.25rem auto 0; //auto 20px auto 0
        position: absolute;
        top: 5rem; //80px
        width: 0.063rem; //1px
        max-height: 38.75rem; //620px
        z-index: 1;
        @include bp(375) {
            left: 2.6667vw; //0 10px/375px
        }
        @include bp($bp__1000) {
            left: calc(5.556vw - 3.75rem); //80px/1440px - 60px
        }
        @include bp(1440) {
            left: 1.25rem; //20px
        }
    }
}

.page-template-default .site-primary,
.story-template-default .site-primary {
    padding-top: 0;
    padding-bottom: 120px;
}

.content-container .post-thumbnail {
    margin-bottom: 80px;
}

.search .site-main,
.error404 .site-main {
    margin-top: 150px;
    max-width: 1280px;
    @media all and (max-width: 1330px) {
        padding: 0 5%;
    }
    margin: 150px auto 0;

    .search-form {
        display: flex;
    }
}
