// Import variables and mixins.
@import "abstracts/breakpoints-sizes",
"abstracts/colors",
"abstracts/fonts",
"abstracts/mixins";

/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/
@import 'generic/reset', // Normalize/Reset
'generic/print'; // Print styles

/*--------------------------------------------------------------
# Vendor
--------------------------------------------------------------*/
@import 'vendor/icomoon', // Icomoon icon font
'vendor/slick'; // Slick slider font & base theme

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import
'base/base',
'base/buttons',
'base/fields',
'base/hr',
'base/links',
'base/lists',
'base/tables',
'base/typography'; // default site typography;

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
@import 'layouts/archive', // archive and index pages
'layouts/content-wrappers', // Set up container elements.
'layouts/footer', // site footer
'layouts/header', // site header
'layouts/home-page', // home page template styles
'layouts/page-header', // page title section
'layouts/single-post'; // Post detail page

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/

/* Navigation
--------------------------------------------- */
@import 'components/site-nav',
'components/menu-toggle',
'components/post-list-nav';

/* Social and sharing
--------------------------------------------- */
@import 'components/social';

/* Posts and pages
--------------------------------------------- */
@import 'components/accordions',
'components/card-grid',
'components/card-list',
'components/categories',
'components/media',
'components/modal',
'components/post-list',
'components/post-meta',
'components/preview-card',
'components/search',
'components/slider-nav';

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/
@import 'utilities/accessibility'; // Accessibility related
@import 'utilities/alignments';


canvas {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}