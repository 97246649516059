// Preview Card Grid layout
.card-grid {
    --column-gap: 7.5%; //90px/1200px
    --row-gap: 3.4375rem; //55px
    align-items: stretch;
    column-gap: var(--column-gap);
    display: flex;
    flex: 1 1 auto;
    flex-flow: row wrap;
    justify-content: flex-start;
    row-gap: var(--row-gap); //80px
    width: 100%;
    > li {
        align-items: stretch;
        display: flex;
        flex: 1 1 auto;
        flex-flow: column nowrap;
        justify-content: flex-start;
        width: 100%;
        @include bp($bp__600) {
            flex: 1 1 calc(100%/2 - (var(--column-gap)/2));
            min-width: calc(100%/2 - (var(--column-gap)/2));
            max-width: calc(100%/2 - (var(--column-gap)/2));
        }
        @include bp($bp__1000) {
            flex: 1 1 calc(100%/3 - ((var(--column-gap) * 2)/3));
            min-width: calc(100%/3 - ((var(--column-gap) * 2)/3));
            max-width: calc(100%/3 - ((var(--column-gap) * 2)/3));
        }
    }
    .preview-card {
        align-items: flex-start;
        box-shadow: none;
        flex-flow: column nowrap;
        margin: 0;
        min-height: 100%;
        .preview-card-picture,
        .preview-card-figure {
            //aspect-ratio: 1 / 1;
            flex: 0 1 auto;
            position: relative;
            width: 100%;
        }
        .preview-card-meta {
            flex: 1 0 auto;
            width: 100%;
        }
        .post-meta {
            margin: auto 0 0;
        }
    }
}

