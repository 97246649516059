// Preview Card List layout
.card-list {
    @include bp($bp__600) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    > li {
        flex: 1 0 auto;
        padding: 0 0 1.25rem; //0 0 20px
        width: 100%;
        @include bp($bp__800) {
            padding: 0 0 2.5rem; //0 0 40px
            width: calc(50% - 1.25rem); //-20px
            max-width: calc(50% - 1.25rem); //-20px
        }
    }
}

.page-signup {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

.story-pager {
    align-items: center;
    border-color: rgba(#fff, 0.3);
    border-style: solid;
    border-width: 0.0625rem 0 0; //1px
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: 1.25rem 0; //20px 0
    position: relative;
    width: 100%;
    @include bp($bp__600) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
    @include bp($bp__800) {

    }
    &::before {
        @include bp($bp__800) {
            background-image: linear-gradient(rgba(#fff, 0.2),  rgba(#fff, 0));
            bottom: 0;
            content: "";
            display: block;
            height: 19.625rem; //314px
            left: 0; //20px
            margin: auto;
            position: absolute;
            right: 0;
            top: 0; //40px
            width: 0.063rem; //1px
            max-height: 38.75rem; //620px
            z-index: 1;
        }
    }
    li.story-preview {
        min-height: 331px;
        position: relative;
        width: 100%;
        @include bp($bp__800) {
            flex: 0 1 50%;
            width: auto;
        }
        &.next {
            @include bp($bp__800) {
                justify-self: flex-end;
                padding: 0 0 0 9.375%;
            }
            h4.story-label {
                text-align: right;
            }
        }
        &.prev {
            @include bp($bp__800) {
                padding: 0 9.375% 0 0;
            }
        }
    }
    li.story-preview {
        align-items: flex-start;
        display: flex;
        flex-flow: column nowrap;
        min-height: 32.5rem; //520px
        justify-content: flex-start;
        @include bp($bp__800) {
            padding: 0 9.375% 0 0;
        }
    }
    a {
        text-decoration: none;
    }
    .story-figure {
        opacity: 0.95;
        position: absolute;
        height: 100%;
        max-width: 335px;
        width: 100%;
        img {
            height: 100%;
            object-fit: contain;
            width: 100%;
        }
    }
    .story-copy {
        margin: auto 0 auto auto;
        width: auto;
        max-width: 16rem; //256px
    }
    h4.story-label {
        flex: 0 1 auto;
        margin: 0;
        width: 100%;
    }
    h5.story-topic {
        @include font($font__heading, $medium);
        @include font-size(14);
        line-height: 1.28571; //18px
        margin: 0 0 1.25rem; //0 0 20px
        text-shadow: 0 0.125rem 1.25rem rgba(#000, 0.5); //0 2px 20px
    }

    h3.story-title {
        @include font-size(26);
        line-height: 1.46154; //38px
        margin: 0 0 1.25rem; //0 0 20px
        text-shadow: 0 0.125rem 1.25rem rgba(#000, 0.5); //0 2px 20px
    }
}


.gallery-slider {
    max-height: 37.5rem; //600px
    overflow: hidden;
    .slick-list {
        margin: 0 auto;
        overflow: visible;
        max-width: 56.25rem; //900px
    }
    figure {
        aspect-ratio: 900 / 600;
        max-height: 37.5rem; //600px
        max-width: 56.25rem; //900px
    }
}