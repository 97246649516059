// styles for Post meta data display

.post-meta {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    row-gap: 0.4375rem; //7px
}

.post-date {
    color: $color__theme_gray;
    @include font-size(14);
    line-height: 1;
}
