// Post Preview Cards
.preview-card {
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    //min-height: 24.625rem; //395px
}

.preview-card-picture,
.preview-card-figure {
    aspect-ratio: 335 / 314;
    flex: 1 0 auto;
    position: relative;
    width: 100%;
    //@include bp($bp__600) {
    //    flex: 0 1 auto;
    //    width: 10rem; //160px
    //    min-width: 10rem; //160px
    //}
    img {
        height: 100%;
        width: 100%;
        left: 0;
        object-fit: contain;
        object-position: center;
        top: 0;
        position: absolute;
    }
}

.preview-card-meta {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    flex: 0 1 auto;
    width: 100%;
    h3.post-title {
        @include font-size(24);
        line-height: 1.3334; //32px
        margin: 0 0 1.125rem; //0 0 18px
        a {
            @include link($color__text_heading, $color__link_hover, none, none );
        }
    }
    .post-text {
        width: 100%;
        p {
            @include font-size(16);
            line-height: 1.5; //24px
            margin: 0 0 1rem; //0 0 16px
        }
    }
}
