// -----------------------------------------------------------------------------
// Styles related to post list navigation.
// -----------------------------------------------------------------------------

.pagination {
    text-align: center;
}

// PAGENAVI
.wp-pagenavi,
.em-pagination {
    margin: 1.875rem 0 2.1875rem; //30px 0px 35px
    text-align: center;
    width: 100%;
    .pages {
        display: none;
        cursor: default;
    }
    a {
        @include link($color__link_main_nav, $color__link_main_nav_hover, none, none);
    }
    a,
    span {
        align-items: center;
        border-width: 0;
        color: $color__theme_gray_dark;
        display: inline-flex;
        flex-flow: row nowrap;
        @include font($font__main, $semibold);
        @include font-size(16);
        justify-content: center;
        height: auto;
        line-height: 2.25; //36px
        margin: 0 0.3125rem 0.3125rem; //0 5px 5px
        padding: 0;
        position: relative;
        text-align: center;
        text-decoration: none;
        transform: translate(0, 0);
        transition-duration: 0.2s;
        transition-property: background-color, color, opacity, transform;
        transition-timing-function: ease;
        vertical-align: middle;
        -webkit-appearance: none !important;
        &:hover,
        &:active,
        &:focus {
            outline: none;
            text-decoration: none;
            &::before {
                opacity: 1;
            }
        }
        &::before {
            background-color: $color__link_main_nav_hover;
            bottom: 0.0625rem; //1px
            content: "";
            height: 0.125rem; //2px
            left: 0;
            margin: 0 auto;
            opacity: 0;
            position: absolute;
            right: 0;
            transition-duration: 0.2s;
            transition-property: background-color, color, opacity, transform;
            transition-timing-function: ease;
            width: 1rem; //16px
        }
        &.page,
        &.current {
            width: 2.5rem; //40px
        }
        &.page {
            &:hover,
            &:active,
            &:focus {
                &::before {
                    opacity: 1;
                }
            }
        }
        &.current {
            color: $color__link_main_nav;
            cursor: default;
            font-weight: $semibold;
            &::before {
                background-color: #e49225;
                opacity: 1;
            }
        }
        &.previouspostslink,
        &.nextpostslink,
        &.prev,
        &.next {
            margin: 0 0.5rem 0.3125rem; //0 8px 5px
            text-indent: -999999px;
            width: 2.25rem; //36px
            &:hover,
            &:active,
            &:focus {
                &::before,
                &::after {
                    color: $color__link_main_nav_hover;
                }
                &::before {
                    transform: translate(-0.1875rem, 0); //-3px
                }
                &::after {
                    transform: translate(0.1875rem, 0); //3px
                }
            }
            &::before,
            &::after {
                color: $color__theme_gray_dark;
                font-family: 'icomoon' !important;
                @include font-size(16);
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                line-height: 1;
                margin: -0.063rem auto 0; //-1px 0 0
                opacity: 1;
                speak: none;
                text-indent: 0;
                text-transform: none;
                transform: translate(0, 0);
                transition-duration: 0.2s;
                transition-property: color, transform;
                transition-timing-function: ease;
                width: 1rem; //16px
            }
        }
        &.previouspostslink,
        &.prev {
            &::before {
                background-color: transparent;
                bottom: auto;
                content: "\ea40";
                height: auto;
            }
            &::after {
                display: none;
            }
        }
        &.nextpostslink,
        &.next {
            &::before {
                display: none;
            }
            &::after {
                content: "\ea3c";
            }
        }
        &.first,
        &.last {
            &::before {
                opacity: 0;
            }
        }
        &.extend {
            width: auto;
            &:hover,
            &:active,
            &:focus {
                color: $color__theme_gray_dark;
                &::before {
                    opacity: 0;
                }
            }
        }
    }
}
