// ----------------------------------------------------------------
// # Search form
// ----------------------------------------------------------------
.search-form {
    display: inline-block;
    position: relative;
    //width: 100%;
    //max-width: 26.25rem; //420px
    //min-width: 16.25rem; //260px
    input[type='text'],
    input[type='search'] {
        margin: 0;
        background-color: $color__theme_gray_lighter;
        border-radius: 100px;
        border-color: $color__theme_gray_lighter;
        color: $color__text_heading;
        @include font($font__main, $semibold);
        @include font-size(20);
        line-height: 1.5; //30px
        padding: 0.75rem 0 0.6875rem 3.375rem; //12px 0 11px 54px
        width: 100%;
        @include placeholder {
            justify-content: center;
            line-height: normal;
            opacity: 1;
        }
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
            opacity: 1;
        }
    }
    button.searchsubmit[type="submit"] {
        background-color: transparent;
        border-color: transparent;
        border-radius: 50%;
        color: $color__text_heading;
        height: 100%;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 3.4375rem; //55px
        min-width: 0;
        z-index: 1;
        &:hover,
        &:active {
            .fa {
                color: $color__link;
            }
        }
        .fa {
            display: inline-block;
            @include font-size(17.5);
            height: 100%;
            position: relative;
            width: 100%;
            &::before {
                bottom: 0;
                display: block;
                height: 1em;
                left: 0;
                line-height: normal;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
    form {
        width: 100%;
    }
}

