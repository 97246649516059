// Default styling for post-lists on archive pages

.post-list {
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    --column-gap: 1.25rem; //20px
    --row-gap: 2.5rem; //40px
    column-gap: var(--column-gap);
    row-gap: var(--row-gap);
    @include bp($bp__600) {
        --row-gap: 5rem; //80px
    }
    &.two-across {
        display: flex;
        flex-flow: column;
        gap: 50px;
        @include bp($bp__1000) {
            flex-flow: row;
            gap: 50px;
        }
        >li {
            @include bp($bp__1000) {
                width: 50%;
            }
            .post-text {
                display: none;
            }
            .preview-card-figure {
                max-width: 14rem;
                max-height: 14rem;
            }
            .preview-card-meta {
                padding-top: 0;
            }
        }
    }
    .preview-card {
        --column-gap: 2.5rem; //40px
        column-gap: var(--column-gap);
        min-height: 100%;
        margin: 0;
        @include bp($bp__600) {
            flex-flow: row nowrap;
            --column-gap: 3.75rem; //60px
        }
    }
    .preview-card-figure {
        @include bp($bp__600) {
            align-self: flex-start;
            flex: 1 0 19.4444vw; //280px/1440px
            height: 19.4444vw; //280px/1440px
            min-height: 7.5rem; //120px
            max-height: 17.5rem; //280px
            width: 19.4444vw; //280px/1440px
            min-width: 7.5rem; //120px
            max-width: 17.5rem; //280px
        }
    }
    .preview-card-meta {
        border-color: $color__theme_paleblue;
        border-style: solid;
        border-width: 0 0 0.063rem; //0 0 1px
        min-height: 100%;
        padding: 1.25rem 0; //20px 0
        @include bp($bp__600) {
            padding-top: 0;
        }
        .post-meta {
            margin: 0 0 1.375rem; //0 0 22px
            padding: 0.1875rem 0 0; //3px
        }
    }
}

.post-list {
    margin-bottom: 80px;
    margin-top: 60px;
    .post-article {

        figure {
            margin-bottom: 35px;
        }
        .copy {
            padding-left: 0;
        }
        
        @include bp($bp__600) {
            display: flex;
            justify-content: space-between;

            figure {
                margin-bottom: 0;
            }

            .copy {
                padding-left: 50px;
            }
        }

        .copy {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: center;
        }

    }
}

.search .preview-card-figure img.default-thumb {
    padding: 10%;
}

