/* Page header styles
--------------------------------------------- */
//page Hero Section
.page-hero {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    position: relative;
    transform: translate(0, 0);
    transform-style: preserve-3d;
    width: 100%;
    .hero-figure {
        background-color: #000;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        will-change: transform;
        &::before {
            background-color: #000;
            content: "";
            display: block;
            height: 100%;
            left: 0;
            opacity: 0.3;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        &::after {
            background-image: linear-gradient(180deg, #000 0%, rgba(#000, 0) 100%);
            content: "";
            display: block;
            height: 12.5rem; //200px
            left: 0;
            opacity: 0.3;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }
    .hero-content {
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        min-height: 41.6875rem; //667px
        justify-content: center;
        margin: 0 auto;
        padding: 12.5rem 0; //200px 0
        position: relative;
        text-align: center;
        width: 100%;
        @include bp($bp__800) {
            min-height: 60rem; //800px
            //padding: 16.875rem 0 12.25rem; //270px 0 196px
        }
        //.preload & {
        //    opacity: 0;
        //}
        > .content-container {
            max-width: 50rem; //800px
        }
        h1.page-title {
            color: #fff;
            @include font-size(38);
            line-height: 1.3158; //50px
            margin: 0 0 2.5rem; //0 0 40px
            text-shadow: 0 0.125rem 1.875rem rgba(#000, 0.50); //0 2px 30px
            @include bp($bp__800) {
                @include font-size(60);
                line-height: 1.2; //72px
            }
        }
        .page-subheading {
            margin: 0 auto;
        }
        p {
            color: #fff;
            @include font-size(16);
            line-height: 1.625; //26px
        }
    }
}
