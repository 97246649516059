
// default styles for slick slider nav
// Slick pager
.slick-dots {
    display: inline-block;
    line-height: normal;
    position: relative;
    text-align: center;
    li {
        align-items: flex-start;
        background-color: rgba($color__theme_blue, 0);
        border-radius: 50%;
        cursor: pointer;
        display: inline-flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 1.5rem 1.125rem; //24px 18px
        padding: 0.125rem; //2px
        position: relative;
        transition-duration: 0.2s;
        transition-property: background-color;
        transition-timing-function: ease;
        &:hover,
        &:active {
            background-color: rgba($color__theme_blue, 1);
        }
        &.slick-active {
            background-color: rgba($color__theme_blue, 1);
            > button {
                background-color: #fff;
            }
        }
        &.slick-active {
            cursor: default;
            > button {
                cursor: default;
            }
        }
        > button {
            appearance: none;
            background-color: $color__theme_blue;
            border: none;
            border-radius: 50%;
            display: block;
            height: 0.5rem; //8px
            padding: 0;
            text-indent: -99999px;
            transition-duration: 0.2s;
            transition-property: background-color;
            transition-timing-function: ease;
            width: 0.5rem; //5px
        }
    }
}

.slick-prev,
.slick-next {
    display: inline-flex;
    @include font($font__main, $semibold);
    @include font-size(14);
    text-decoration: none;
    &:hover,
    &:active,
    &.slick-active {
        color: #fff;
        text-decoration: none;
    }
}
