/* Text meant only for screen readers. */
.screen-reader-text {
    @include visually-hidden;

    &:focus {
        background-color: $color__bg_screen;
        border-radius: 3px;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
        clip: auto !important;
        clip-path: none;
        color: $color__text_main;
        display: block;
        font-size: 0.875rem;
        font-weight: 700;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000; // Above WP toolbar.
    }
}

/* Do not show the outline on the skip link target. */
#primary[tabindex="-1"]:focus {
    outline: 0;
}
