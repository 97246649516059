// -----------------------------------------------------------------------------
// Print styles
// -----------------------------------------------------------------------------

@media print {
    .print {
        display: block;
    }
    @page {
        margin: 1.5in 1in 1in;
        size: 8.5in 11in portrait;
    }
    *,
    *::before,
    *::after,
    p:first-letter,
    div:first-letter,
    blockquote:first-letter,
    li:first-letter,
    p:first-line,
    div:first-line,
    blockquote:first-line,
    li:first-line {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        filter: none !important;
        height: auto !important;
        text-align: left !important;
        text-shadow: none !important;
        -ms-filter: none !important;
    }
    #header,
    #footer,
    .social,
    .slick-arrow,
    .edit-link,
    .gform_wrapper,
    blockquote::before {
        display: none !important;
    }
    .page-header {
        background: #ffffff;
        margin-bottom: 0;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    a[href^="javascript:"]:after, a[href^="#"]:after {
        content: "";
    }
    // Don't show links for images, or javascript/internal links
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    // Tables
    thead {
        display: table-header-group;
    }
    tr, img {
        page-break-inside: avoid;
    }
    //  Print Typography
    p, h2, h3 {
        orphans: 3;
        widows: 3;
    }
    h2, h3 {
        page-break-after: avoid;
    }
    h1 {
        font-size: 2.5em !important;
    }
    h2 {
        font-size: 2.25em !important;
    }
    h3 {
        font-size: 2em !important;
    }
    h4 {
        font-size: 1.75em !important;
    }
}