// Hamburger Mobile Menu Icon

// http://callmenick.com/_development/css-hamburger-menu-icons/
$button_color: $color__menu_toggle;
$button_color_hover: $color__menu_toggle_hover;
$button_bar_thickness: 2px; // The thickness of the button bars
$button_bar_width: 22px; // The width of the button bars
$button_padding_left: 60px; // The left/right padding between button area and bars
$button_padding_right: 20px; // The left/right padding between button area and bars
$button_bar_space: 2px; // The spacing between button bars
// If tablet width is different
$tablet_button_padding_left: 66px; // The left/right padding between button area and bars
$tablet_button_padding_right: 40px; // The left/right padding between button area and bars

.menu-toggle {
    align-items: center;
    background-color: transparent;
    border: none;
    bottom: 0;
    color: $button_color;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    font-size: 0;
    height: 100%;
    justify-content: flex-end;
    padding: 0 $button_padding_right 0 $button_padding_left;
    position: absolute;
    right: 0;
    text-transform: none;
    transform: translate3d(0, 0, 0);
    transition-duration: 0.3s;
    transition-property: background-color, bottom, top, transform;
    transition-timing-function: ease;
    min-width: $button_bar_width + $button_padding_left + $button_padding_right;
    z-index: 3;
    @include bp(375) {
        $button_padding_right: 5.334vw;
        padding: 0 $button_padding_right 0 $button_padding_left;
    }
    @include bp($bp__600) {
        padding: 0 $tablet_button_padding_right 0 $tablet_button_padding_left;
        min-width: $button_bar_width + $tablet_button_padding_left + $tablet_button_padding_right;

    }
    &::after {
        color: $button_color;
        content: 'Menu';
        display: block;
        @include font($font__main, $semibold);
        @include font-size(12);
        left: 0;
        letter-spacing: 0.0425em;
        line-height: normal;
        margin: auto;
        position: absolute;
        text-align: center;
        transform: translate3d(0, -50%, 0);
        transition-duration: 0.3s;
        transition-property: color;
        transition-timing-function: ease;
        top: 50%;
        width: $button_padding_left;
        @include bp($bp__600) {
            @include font-size(14);
            width: $tablet_button_padding_left;
        }
        .home-page-template.header-top &,
        [data-page="pressroom"].header-top & {
            color: #fff;
        }
        .js-nav .home-page-template.header-top .site-header &,
        .js-nav [data-page="pressroom"].header-top .site-header &,
        .home-page-template.header-top .site-header:hover &,
        [data-page="pressroom"].header-top .site-header:hover & {
            color: $button_color;
        }
    }
    &:hover,
    &:active {
        color: $button_color_hover;
        text-decoration: none;
    }
    &:focus {
        outline: none;
    }
    &:hover {
        span {
            background-color: $button_color_hover;
            &::before {
                background-color: $button_color_hover;
                transform: translate3d(0, 0, 0) rotate(0deg);
                width: $button_bar_width;
            }
            &::after {
                background-color: $button_color_hover;
                transform: translate3d(0, 0, 0) rotate(0deg);
                width: $button_bar_width;
            }
        }
    }
    &.is-active {
        &::after {
            content: 'Close';
        }
        span {
            background-color: transparent;
            &::before,
            &::after {
                width: 26px;
            }
            &::before {
                top: $button_bar_thickness + $button_bar_space;
                transform: translate3d(0, 0, 0) rotate(45deg);
                transition-delay: 0s, 0s, 0s, 0s, 0.3s;
            }
            &::after {
                top: $button_bar_thickness + $button_bar_space;
                transform: translate3d(0, 0, 0) rotate(-45deg);
                transition-delay: 0s, 0s, 0s, 0s, 0.3s;
            }
        }
    }
    // Hamburger to "x". Takes on a hamburger shape, bars slide
    // down to center and transform into an "x".
    span {
        background-color: transparent;
        display: block;
        font-size: 0;
        height: $button_bar_thickness;
        left: 0.0625rem; //1px
        margin: auto 0;
        outline: 1px solid transparent;
        transform: translate3d(0, -$button_bar_thickness - $button_bar_space, 0);
        transition-duration: 0.3s;
        transition-property: background-color, height, transform, width;
        transform-style: preserve-3d;
        transition-timing-function: ease;
        width: $button_bar_width;
        &::before,
        &::after {
            background-color: $button_color;
            content: "";
            display: block;
            height: $button_bar_thickness;
            left: 0;
            outline: 1px solid transparent;
            position: absolute;
            transform: translate3d(0, 0, 0) rotate(0deg);
            transition-delay: 0s, 0.2s, 0s, 0s, 0s, 0s;
            transition-duration: 0.3s;
            transition-property: background-color, bottom, height, top, transform, width;
            transform-origin: center center;
            transform-style: preserve-3d;
            transition-timing-function: ease;
            width: $button_bar_width;
        }
        &::before {
            top: 0;
        }
        &::after {
            top: 2 * ($button_bar_thickness + $button_bar_space);
        }
    }
}
