// Basic typography style for copy text

html body {
    color: $color__text_main;
    @include font($font__main, $regular);
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
    @include font($font__heading, $bold);
    word-break: keep-all;
    .page-content & {
        color: $color__text_heading;
    }
    a {
        font-size: inherit;
        font-weight: inherit;
        text-decoration-line: underline;
        text-decoration-color: currentColor;
        text-decoration-thickness: 0.125rem; //2px
        &:hover,
        &:focus,
        &:active {
            text-decoration-thickness: 0.125rem; //2pxs
        }
    }

}

h1 {
    @include font-size(42);
    line-height: 1.25; //60px/48px
    @include bp($bp__600) {
        @include font-size(48);
    }
}

h2 {
    @include font-size(36);
    line-height: 1.3333; //48px/36px
}

h3 {
    @include font-size(30);
    line-height: 1.4; //42px/30px
}

h4 {
    @include font-size(24);
    line-height: 1.5; //36px/24px
}

h5 {
    @include font-size(20);
    line-height: 1.4; //28px/20px
}

h6 {
    @include font-size(16);
    line-height: 1.5; //24px
}

// Base Text
p {
    line-height: 1.75; //28px
    margin: 0 0 1.75rem; //0 0 28px
}

dfn,
em,
i,
var {
    font-style: italic;
    strong,
    strong &,
    b,
    b & {
        font-style: italic;
        font-weight: bold;
    }
}

q {
    quotes: inherit;
    &::before {
        content: open-quote;
    }
    &::after {
        content: close-quote;
    }
}

//pre,
code {
    margin: 0 0 1.5em;
}

//pre,
code,
tt,
kbd {
    font-family: $font__code;
    font-size: 90%;
    letter-spacing: normal;
    white-space: pre-wrap;
}

pre {
    font: inherit;
}

del {
    color: $color__red;
}

ins {
    color: $color__green;
}

//sub {
//}
//
//sup {
//}

address {
    margin: 1em 0;
}


.filetype {
    text-transform: uppercase;
}

caption {
}

// highlight - for search
.hls {
    background-color: $color__highlight;
}

//USER ENTERED CONTENT
.entry-content {
    > *:first-child {
        margin-top: 0;
        @include bp($bp__600) {

        }
    }
    > [class*="wp-block-"] {
        clear: both;
        margin-bottom: 1.875rem; //30px
        .twitter-tweet {
            margin-top: 0;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color__text_heading;
        margin: 1.875rem 0; //30px 0
        &.alignfull {
            margin-left: -1.25rem; //-20px
            margin-right: -1.25rem; //-20px
            width: calc(100vw - var(--scrollbar));
            min-width: calc(100vw - var(--scrollbar));
            max-width: calc(100vw - var(--scrollbar));
            @include bp(375) {
                margin-left: calc((calc(100vw - var(--scrollbar)) - 100%)/-2); //(100vw-900px)/-2)
                margin-right: calc((calc(100vw - var(--scrollbar)) - 100%)/-2); //(100vw-900px)/-2)
            }
            @include bp($bp__1000) {
                margin-bottom: 1.875rem; //50px
            }
        }
        &.alignwide {
            width: auto;
            min-width: 100%;
            max-width: 75rem;
            @include bp($bp__1000) {
                margin-bottom: 3.125rem; //50px
                margin-left: calc(((100vw - 100%)/-2) * 0.625); //(100vw-900px)/-2) * (200px/320px)
                margin-right: calc(((100vw - 100%)/-2) * 0.625); //(100vw-900px)/-2) * (200px/320px)
            }
            @include bp($bp__1400) {
                margin-left: -12.5rem; //-200px
                margin-right: -12.5rem; //-200px
            }
        }
        a {
            @include link($color__heading_link, $color__heading_link_hover, underline, underline);
        }
    }
    p {
        @include font-size(18);
        line-height: 1.667; //30px
        margin: 0 0 1.875rem; //0 0 30px
        a {
            color: $color__link;
            font-weight: $semibold;
            text-decoration: underline;
            &:hover,
            &:active,
            &:focus {
                color: $color__link_hover;
                text-decoration: underline;
            }
            &[class*="arrow-link"] {
                color: $color__text_heading;
                font-weight: $semibold;
                text-decoration: none;
                &:hover,
                &:active,
                &:focus {
                    color: $color__link_hover;
                    text-decoration: none;
                }
            }
        }
    }
    // Blockquote, Pullquote, Citation
    .wp-block-pullquote {
        padding: 0;
    }
    blockquote {
        border: none;
        margin: 3.5rem 0 3.75rem; //56px 0 60px
    }
    blockquote {
        border: none;
        color: $color__text_blockquote;
        @include font($font__heading, $semibold, italic);
        @include font-size(26);
        line-height: 1.4615384615; //38px
        position: relative;
        ////override WordPress large blockquote default block styles
        //quotes: "\201C" "\201D" "\2018" "\2019";
        width: 100%;
        @include bp($bp__600) {
            @include font-size(36);
            line-height: 1.3334; //48px
        }
        > *:first-child {
            margin-top: 0;
            @include bp($bp__600) {

            }
        }
        p {
            @include font-size(26);
            line-height: 1.46154; //38px
            hanging-punctuation: first;
            position: relative;
            @include bp($bp__800) {
                @include font-size(36);
                line-height: 1.5556; //56px
            }
        }
        cite {
            color: $color__text_blockquote;
            display: block;
            @include font($font__main, $regular);
            @include font-size(14);
            font-style: normal;
            line-height: normal;
            margin: 2.3125rem 0 0; //37px 0 0
            position: relative;
            text-transform: initial;
            &::before {
                content: "";
                background-color: #bfbfbf;
                display: inline-block;
                height: 1px;
                margin: 0.25rem 1.25rem 0.25rem 0; //4px 20px 4px 0
                width: 2.1875rem; //35px
            }
        }
    }
    cite {
        margin: 1rem 0; //16x 0
    }
    dt {
        font-weight: bold;
    }
    dd {
        margin: 0 0 1rem;
    }
    dl,
    table {
        margin: 0 0 2rem;
        width: 100%;
        &.dataTable {
            thead,
            tfoot {
                th {
                    padding: 10px 18px 10px 10px;
                    &:first-child {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    iframe {
        margin: 0 0 1.75rem; //0 0 28px
    }
    form,
    .gform_wrapper form {
        margin: 0 0 1.75rem; //0 0 28px
    }
    // Gutenberg options
    .has-text-color,
    .has-inline-color {
        &.has-gray-color {
            color: $color__theme_gray;
        }
    }
    .has-background {
        &.has-gray-background-color {
            background-color: $color__theme_gray;
        }
    }
    // Embedded media
    // Featured image {
    .post-thumbnail {
        margin: 1.875rem 0; //30px 0
        width: 100%;
    }
    // Button block
    .wp-block-buttons {
        margin: 0 0 1.875rem; //0 0 30px
        &.alignleft {
            margin: 0 1.5rem 1.875rem 0; //0 24px 30px 0
        }
        &.alignright {
            margin: 0 0 1.875rem 1.5rem; //0 0 30px 24px
        }
    }
    // Media & Test block
    .wp-block-media-text:not(.alignfull) {
        &.has-media-on-the-right {
            .wp-block-media-text__content {
                @include bp($bp__600) {
                    padding-left: 0;
                    padding-right: 8%;
                }
            }
        }
        .wp-block-media-text__content {
            @include bp($bp__600) {
                padding-right: 0;
            }
        }
    }
}

// end .entry-content, #tinymce.mce-content-body
//end USER ENTERED CONTENT
