// Icomoon icon font styles
// 'icomoon' @font-face declared in abstracts/_fonts.scss

[class^="fa-"],
[class*=" fa-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: never;
    text-transform: none;
}

.fa-angle-down::before {
    content: "\f107";
}

.fa-chevron-down::before {
    content: "\f107";
}

.fa-angle-left::before {
    content: "\f104";
}

.fa-chevron-left::before {
    content: "\f104";
}

.fa-angle-right::before {
    content: "\f105";
}

.fa-chevron-right::before {
    content: "\f105";
}

.fa-angle-up::before {
    content: "\f106";
}

.fa-chevron-up::before {
    content: "\f106";
}

.fa-arrow-down::before {
    content: "\e900";
}

.fa-arrow-left::before {
    content: "\e901";
}

.fa-arrow-left2::before {
    content: "\ea40";
}

.fa-arrow-right::before {
    content: "\e902";
}

.fa-arrow-right2::before {
    content: "\ea3c";
}

.fa-arrow-up::before {
    content: "\e903";
}

.fa-bordered-caret-left::before {
    content: "\f0de";
}

.fa-bordered-caret-right::before {
    content: "\f0df";
}

.fa-calendar::before {
    content: "\f073";
}

.fa-caret-down::before {
    content: "\f0da";
}

.fa-caret-left::before {
    content: "\f0dc";
}

.fa-caret-right::before {
    content: "\f0dd";
}

.fa-caret-up::before {
    content: "\f0db";
}

.fa-checkmark::before {
    content: "\e904";
}

.fa-close::before {
    content: "\f00d";
}

.fa-remove::before {
    content: "\f00d";
}

.fa-times::before {
    content: "\f00d";
}

.fa-desktop::before {
    content: "\f108";
}

.fa-download::before {
    content: "\e960";
}

.fa-earth::before {
    content: "\e9ca";
}

.fa-envelope::before {
    content: "\f003";
}

.fa-facebook::before {
    content: "\f09a";
}

.fa-facebook-f::before {
    content: "\f09a";
}

.fa-file-excel-o::before {
    content: "\f1c3";
}

.fa-file-o::before {
    content: "\f016";
}

.fa-file-pdf-o::before {
    content: "\f1c1";
}

.fa-file-powerpoint-o::before {
    content: "\f1c4";
}

.fa-file-text-o::before {
    content: "\f0f6";
}

.fa-file-word-o::before {
    content: "\f1c2";
}

.fa-globe::before {
    content: "\e9c9";
}

.fa-google::before {
    content: "\f1a0";
}

.fa-instagram::before {
    content: "\f16d";
}

.fa-linkedin::before {
    content: "\f0e1";
}

.fa-mail::before {
    content: "\e945";
}

.fa-map-marker::before {
    content: "\f041";
}

.fa-media-play::before {
    content: "\f04b";
}

.fa-minus::before {
    content: "\f068";
}

.fa-new-tab::before {
    content: "\ea7e";
}

.fa-pause::before {
    content: "\e905";
}

.fa-phone::before {
    content: "\f095";
}

.fa-pinterest-p::before {
    content: "\f231";
}

.fa-play::before {
    content: "\ea1c";
}

.fa-play2::before {
    content: "\ea15";
}

.fa-plus::before {
    content: "\f067";
}

.fa-podcast::before {
    content: "\e91c";
}

.fa-quotes-left::before {
    content: "\e977";
}

.fa-search::before {
    content: "\f002";
}

.fa-share::before {
    content: "\ea82";
}

.fa-shop::before {
    content: "\e93a";
}

.fa-skype::before {
    content: "\f17e";
}

.fa-snapchat-ghost::before {
    content: "\f2ac";
}

.fa-tumblr::before {
    content: "\f173";
}

.fa-twitter::before {
    content: "\f099";
}

.fa-vimeo::before {
    content: "\f27d";
}

.fa-youtube-play::before {
    content: "\f16a";
}
