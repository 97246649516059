
.site-primary {
    // WP Core Blocks
    &.wide-primary {
        [class*="entry-block"],
        .entry-block-embed,
        .entry-block-image {
            &.alignwide,
            > .alignwide {
                @include bp($bp__1000) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
    [class*="entry-block"],
    .entry-block-embed,
    .entry-block-image {
        &.alignleft,
        &.alignright,
        > .alignleft,
        > .alignright {
            @include bp($bp__480) {
                max-width: 50%;
            }
            @include bp($bp__600) {
                margin-top: 0;
            }
        }
        &.alignfull,
        > .alignfull {
            margin-left: -1.25rem; //-20px
            margin-right: -1.25rem; //-20px
            width: calc(100vw - var(--scrollbar));
            min-width: calc(100vw - var(--scrollbar));
            max-width: calc(100vw - var(--scrollbar));
            @include bp(375) {
                margin-left: calc((calc(100vw - var(--scrollbar)) - 100%) / -2); //(100vw-900px)/-2)
                margin-right: calc((calc(100vw - var(--scrollbar)) - 100%) / -2); //(100vw-900px)/-2)
            }
        }
        &.alignwide,
        > .alignwide {
            width: auto;
            min-width: 100%;
            max-width: 75rem;
            @include bp($bp__1000) {
                margin-left: calc(((100vw - 100%) / -2) * 0.5556); //(100vw-900px)/-2) * (150px/270px)
                margin-right: calc(((100vw - 100%) / -2) * 0.5556); //(100vw-900px)/-2) * (150px/270px)
            }
            @include bp($bp__1400) {
                margin-left: -9.375rem; //-150px
                margin-right: -9.375rem; //-150px
            }
        }
        > figure {
            @include bp-max($bp__600) {
                float: none;
                margin-left: unset;
                margin-right: unset;
                margin-bottom: 2.5rem; //40px
            }
            img {
                width: 100%;
            }
        }
        + .entry-block-columns {
            @include bp-max($bp__1000) {
                flex-wrap: wrap;
            }
            .entry-block-column {
                @include bp-max($bp__1000) {
                    flex-grow: 1;
                    flex-basis: 100% !important;
                }
                &:not(:first-child) {
                    @include bp-max($bp__1000) {
                        margin-left: 0;
                    }
                }
                > .alignwide,
                > .alignfull {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        figcaption {
            color: $color__text_main;
            @include font($font__main, $regular, italic);
            @include font-size(12);
            margin: 0.75rem 0 0; //12px 0 0
            text-align: left;
        }
    }
}


.alignleft {
    @include bp($bp__800) {
        /*rtl:ignore*/
        float: left;
        /*rtl:ignore*/
        margin-right: 1.5rem;
    }
}

.alignright {
    @include bp($bp__800) {
        /*rtl:ignore*/
        float: right;
        /*rtl:ignore*/
        margin-left: 1.5rem;
    }
}

.aligncenter {
    clear: both;
    @include center-block;
}

.alignnone {
    clear: both;
}

// full window width content, using default WP class name
// limit to sites without a sidebar
.no-sidebar .alignfull {
    //@include fullwidth;
}

.entry-block-image {
    @include bp($bp__800) {
        .alignleft {
            margin-right: 2rem;
        }
        .alignright {
            margin-left: 2.5rem;
        }
    }
}

//columned text block
.entry-columns-block {
    margin: 0 0 1.75rem; //0 0 28px
    @include bp($bp__1000) {
        columns: 2;
        column-gap: 10%;
    }
}