// Modal styles

//html.modal-visible {
//    overflow-y: scroll;
//    body {
//        overflow-y: hidden;
//    }
//}


.modal {
    align-items: center;
    background: rgba(#000, 0.8);
    height: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transition-delay: 0.4s, 0s, 0.4s, 0.4s;
    transition-duration: 0s, 0.4s, 0s, 0s;
    transition-property: height, opacity, width, z-index;
    transition-timing-function: ease-in;
    width: 0;
    z-index: -1;
    .modal-visible & {
        height: 100%;
        min-height: 100vh;
        left: 0;
        opacity: 1;
        overflow-y: hidden;
        pointer-events: auto;
        scrollbar-width: none;
        top: 0;
        transition-delay: 0s, 0.4s, 0s, 0s;
        width: 100%;
        z-index: 600;
    }
}

.modal-content-wrapper {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    opacity: 0;
    padding: 3.75rem 0; //60px 0
    position: relative;
    transform: translate3d(0, 10vh, 0);
    top: 0;
    transition-delay: 0.1s;
    transition-duration: 0.3s;
    transition-property: opacity, transform;
    transition-timing-function: ease-out;
    width: 100%;
    .modal-visible & {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition-delay: 0.4s;
    }
}

.close-modal {
    align-items: center;
    @include link(#fff, $color__link_main_nav_hover, none, none);
    display: flex;
    flex-flow: row wrap;
    height: 3.75rem; //60px
    justify-content: center;
    line-height: 32px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    top: 0;
    width: 3.75rem; //60px
    z-index: 1;
    .fa-close {
        @include font-size(30);
        font-weight: bold;
    }
}

.modal-content {
    align-items: center;
    background-color: #000;
    box-shadow: 0.125rem 0.125rem 2.5rem 0 rgba(#000, 0.5); //2px 2px 40px
    display: flex;
    flex-flow: column nowrap;
    min-height: 100%;
    justify-content: flex-start;
    margin: 0 auto;
    width: 100%;
    @include bp($bp__600) {
        align-items: stretch;
        flex-flow: row nowrap;
    }
    .video-embed {
        margin: auto 0;
    }
}

.modal-figure {
    align-items: center;
    background-color: $color__theme_blue_dark;
    background-position: center top;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    flex: 1 0 auto;
    height: 53.333vw; //200px/375px
    min-height: 12.5rem; //200px
    text-align: center;
    width: 100%;
    @include bp($bp__600) {
        flex: 1 0 40.23%; //350px/870px
        height: auto;
        min-height: 100%;
        width: 40.23%;
    }
    video,
    img {
        height: 100%;
        object-fit: cover;
        object-position: top;
        width: 100%;
    }
}

.modal-page-content {
    flex: 1 1 auto;
    margin: 0 auto;
    padding: 2.5rem 1.25rem; //40px 20px
    position: relative;
    width: 100%;
    @include bp(375) {
        padding: 2.5rem 5.334vw; //40px 20px/375px
    }
    @include bp($bp__600) {
        padding: 2.5rem 6vw; //40px 60px/1000px
    }
    @include bp($bp__1000) {
        padding: 2.5rem 3.75rem; //40px 60px
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color__text_heading;
        margin: 0 0 1.875rem; //0 0 30px
    }
    h1 {
        @include font-size(32);
    }
    h2 {
        @include font-size(28);
    }
    h3 {
        @include font-size(24);
    }
    h4 {
        @include font-size(20);
    }
    p {
        line-height: 1.5; //24px
        margin: 0 0 1.3125rem; //0 0 21px
    }
}
