table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 1.5em;
    width: 100%;
}

th {
    vertical-align: middle;
}

td {
    vertical-align: top;
}
