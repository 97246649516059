/*  Form fields
--------------------------------------------- */

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type='email'],
input[type="month"],
input[type="number"],
input[type='password'],
input[type="range"],
input[type="search"],
input[type='tel'],
input[type="time"],
input[type='text'],
input[type="url"],
input[type="week"],
select,
textarea {
    appearance: none;
    background-color: $color__bg_input;
    border-color: $color__border_input;
    border-radius: 62.5rem; //100px
    border-style: solid;
    border-width: 0.063rem; //1px;
    color: $color__text_input;
    @include font($font__main, $regular);
    @include font-size(14);
    line-height: normal;
    letter-spacing: normal;
    margin: 0 0 0.5rem; //0 0 8px
    outline: 0;
    padding: 1rem 1.25rem; //16px 20px
    position: relative;
    text-transform: none;
    transition-duration: 0.2s;
    transition-property: background-color, border-color, color;
    transition-timing-function: ease;
    width: 100%;
    @include bp($bp__800) {
    }
    &::placeholder {
        color: $color__placeholder;
    }
    &:focus {
        border-color: $color__border_input_focus;
        outline: 0;
        transition-duration: 0.2s;
        transition-property: background-color, border-color, color;
        transition-timing-function: ease;
        @include placeholder {
            opacity: 0.6;
        }
    }
    //&:blur {
    //    transition-duration: 0s;
    //    @include placeholder {
    //        transition-duration: 0s;
    //    }
    //}
    &:invalid {
        //background-color: $highlight;
    }
    &:focus:valid {
        //background-color: rgba($color__success, 0.2);
    }
    @include placeholder {
        transition-property: opacity;
        transition-duration: 0.2s;
        color: $color__placeholder;
        opacity: 0.6;
        .safari & {
            line-height: 1.5625; //25px
        }
    }
    &.placeholder {
        color: $color__placeholder;
    }
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: $color__placeholder;
        opacity: 0.6;
    }
}

textarea {
    min-height: 8.125rem; //130px
    max-width: 100%;
    min-width: 100%;
    overflow: auto;
    &:-ms-input-placeholder {
        min-height: 8.75em; //140px
    }
    @include placeholder {
        line-height: normal;
    }
    &[readonly="readonly"] {
        cursor: default;
    }
}

select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='0 0 14 9'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23262626;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-select%3C/title%3E%3Cpath class='a' d='M13.837,2.393a.561.561,0,0,0,0-.79L12.4.169a.551.551,0,0,0-.782,0L7,4.782,2.387.169a.551.551,0,0,0-.782,0L.163,1.6a.561.561,0,0,0,0,.79L6.609,8.831a.551.551,0,0,0,.782,0Z'/%3E%3C/svg%3E");
    //background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%227%22%20viewBox%3D%220%200%2012%207%22%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill%3A%23212121%3B%7D%3C/style%3E%3C/defs%3E%3Cpath%20class%3D%22a%22%20d%3D%22M6%2C7%2C0%2C0H12Z%22/%3E%3C/svg%3E");
    background-position: calc(100% - 0.875rem) 54%; //100% - 14px
    background-repeat: no-repeat;
    background-size: 0.5rem auto; //8px auto
    cursor: pointer;
    filter: none;
    letter-spacing: normal;
    //line-height: 1.8125rem !important; //!important
    padding: 0.5625rem 2rem 0.5625rem 1rem; //11px 32px 9px 16px
    text-overflow: '';
    z-index: 1;
    &:focus {
        filter: none;
        outline: 0;
    }
    &:-moz-focusring {
        color: transparent;
        outline: none;
        text-shadow: 0 0 0 $color__text_input;
    }
    &::-ms-expand {
        display: none;
    }
    &.input-filled {
        color: $color__text_input;
    }
    option {
        background-color: #fff;
        color: $color__text_input;
        filter: none;
        padding: 0.25rem 0 0.25rem 1rem; //4px 0 4px 16px
        &.empty {
        }
    }
}

// specific form
.updates-form {
    border-radius: 62.5rem; //100px
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 20rem; //320px
    &::before {
        backdrop-filter: blur(1rem);
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    input[type="text"],
    input[type="email"] {
        margin: 0;
    }
    button[type="submit"] {
        background-color: transparent;
        border-width: 0;
        box-shadow: none;
        height: 100%;
        padding: 0;
        position: absolute;
        right: 0;
        text-indent: -999999px;
        top: 0;
        width: 3.25rem; //52px
        min-width: 0;
        &::after {
            color: $color__text_heading;
            content: "\e945"; //mail
            flex: 0 1 auto;
            font-family: icomoon !important;
            @include font-size(24);
            font-weight: normal;
            line-height: 3.25rem; //52px
            position: absolute;
            right: 0.875rem; //14px
            text-align: center;
            text-indent: 999999px;
            top: 0;
            transform: translate(0, 0);
        }
    }
}
