// -----------------------------------------------------------------------------
// This file contains custom theme @font-face declarations and definitions.
// -----------------------------------------------------------------------------
// Custom theme fonts
// Add @font-face declarations for commercial fonts; put font files in assets/fonts


// Icomoon - custom icon font
@font-face {
    font-display: block;
    font-family: 'icomoon';
    font-style: normal;
    font-weight: normal;
    src:
        url('../fonts/icomoon/icomoon.ttf?dh0eyl') format('truetype'),
        url('../fonts/icomoon/icomoon.woff?dh0eyl') format('woff'),
        url('../fonts/icomoon/icomoon.svg?dh0eyl#icomoon') format('svg');
}

//@font-face {
//    font-display: block;
//    font-family: 'Libre Franklin';
//    font-style: normal;
//    font-weight: normal;
//    src:
//        url('../fonts/poppins/Poppins-Regular.ttf') format('truetype');
//}
//
//@font-face {
//    font-display: block;
//    font-family: 'Libre Franklin';
//    font-style: normal;
//    font-weight: bold;
//    src:
//        url('../fonts/poppins/Poppins-Bold.ttf') format('truetype');
//}
//
//
//@font-face {
//    font-display: block;
//    font-family: 'Libre Franklin';
//    font-style: normal;
//    font-weight: 500;
//    src:
//        url('../fonts/poppins/Poppins-Medium.ttf') format('truetype');
//}
//
//@font-face {
//    font-display: block;
//    font-family: 'Libre Franklin';
//    font-style: italic;
//    font-weight: 500;
//    src:
//        url('../fonts/poppins/Poppins-MediumItalic.ttf') format('truetype');
//}
//
//@font-face {
//    font-display: block;
//    font-family: 'Montserrat';
//    font-style: normal;
//    font-weight: normal;
//    src:
//        url('../fonts/redhatdisplay/RedHatDisplay-Bold.ttf') format('truetype');
//}
//
//@font-face {
//    font-display: block;
//    font-family: 'Montserrat';
//    font-style: italic;
//    font-weight: normal;
//    src:
//        url('../fonts/redhatdisplay/RedHatDisplay-BoldItalic.ttf') format('truetype');
//}

// Declare Google fonts on /includes/_project-config.php
$font__main: 'Libre Franklin';
$font__heading: 'Montserrat';

$font__code: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

$font__main_stack: sans-serif;
$font__heading_stack: sans-serif;

$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$book: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

@mixin font($font_face: $font__main, $weight: $regular, $font_style: normal) {
    @if $font_face == $font__main {
        font-family: "#{$font-face}", $font__main_stack;
        font-weight: $weight;
    } @else if $font_face == $font__heading {
        font-family: "#{$font-face}", $font__heading_stack;
        font-weight: $weight;
    } @else {
        font-family: "#{$font-face}", $font__main_stack;
        font-weight: $weight;
    }
    font-style: $font_style;
    font-display: swap;
}
