// ACCORDIONS.
.accordion-container {
    border-color: $color__theme_blue;
    border-style: solid;
    border-width: 0.125rem 0 0; //2px 0 0
    margin: 0 0 1.875rem; //0 0 30px
    @include bp($bp__600) {
        margin: 0 0 2.5rem; //0 0 40px
    }
    .accordion-section {
        position: relative;
        width: 100%;
        &::after {
            background-color: $color__theme_blue;
            bottom: 0;
            content: "";
            display: block;
            height: 0.125rem; //2px
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            width: 100%;
        }
        &.open {
            h3.accordion-section-title {
                button.accordion-button {
                    &::after {
                        transform: scaleY(0) rotate(90deg);
                    }
                }
            }
        }
    }
}


h3.accordion-section-title {
    margin: 0;
    overflow: hidden;
    position: relative;
    &:hover,
    &:active,
    &:focus {
        &::after {
            color: $color__link_hover;
        }
    }
}

button.accordion-button {
    appearance: none !important;
    background-color: transparent;
    border: none;
    color: $color__text_heading;
    @include font($font__heading, $bold);
    @include font-size(18);
    min-height: 5.5rem; //88px
    line-height: 1.333; //24px
    padding: 1.1875rem 2rem 1.3125rem 0; //19px 32px 21px 0
    text-align: left;
    width: 100%;
    @include bp($bp__600) {
        padding: 1.1875rem 3.125rem 1.3125rem 0; //19px 50px 21px 0
    }
    &:hover,
    &:active {
        color: $color__link_hover;
        &::before,
        &::after {
            color: $color__theme_blue;
        }
    }
    &::before,
    &::after {
        background-color: $color__theme_blue;
        bottom: 0;
        content: "";
        height: 0.1875rem; //3px
        margin: auto 0;
        position: absolute;
        right: 0;
        top: 0;
        transition-duration: 0.2s;
        transition-property: background-color, transform;
        transition-timing-function: ease-in;
        width: 0.9375rem; //15px
        will-change: transform;
        z-index: 1;
    }
    &::before {
        transform: rotate(0deg);
    }
    &::after {
        transform: scaleY(1) rotate(90deg);
    }
    &:hover {
        &::before,
        &::after {
            background-color: $color__theme_blue;
        }
    }
}

.accordion-section-content {
    padding: 0 0.125rem; //0 2px
    p {
        display: inline-block;
        line-height: 1.625; //26px
        margin: 0 0 1.625rem; //0 0 26px
        width: 100%;
    }
}
