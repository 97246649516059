// Archive page styles

// Archive template
.archive,
.blog {
    &.post-type-archive-issue {
        .post-categories {
            .post-category {
                &:last-of-type {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
    .page-header {
        //background: url(../images/news-archive-header.png) center center / cover no-repeat;
        min-height: 17.5rem; //280px
        position: relative;
        width: 100%;
    }
    .page-title {
        color: #262626;
        font-size: 52px;
        letter-spacing: 0.4px;
        line-height: 68px;
        margin: 80px 0;
        text-align: center;
    }
}

section.archive-content {
    .site-main {
        margin: 0 auto;
        max-width: 62.5rem; //1000px
    }
    .page-header {
        padding: 5rem 0 4rem; //80px 0 64px
        .content-container {
            padding: 0;
        }
    }
    .archive-primary {
        width: 100%;
    }
    .archive-page-content {
        border-color: $color__theme_blue;
        border-style: solid;
        border-width: 0 0 0.0625rem;
        margin: 0 0 2.5rem; //0 0 40px
        padding: 3rem 0; //48px 0
        @include bp($bp__800) {
            padding: 5rem 0 3rem; //80px 0 48px
        }
        h1.page-title {
            margin: 0 0 1.4375rem; //0 0 23px
        }
    }
    .post-list {
        padding: 0 0 2.5rem; //0 0 40px
    }
    .pagination {
        margin: 4.375rem 0 5rem; //70px 0 80px
        @include bp($bp__1000) {
            margin: 4.375rem 0 7.5rem; //70px 0 120px
        }
    }
}

