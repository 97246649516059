// PRIMARY NAV

html.js-ready {
    #site-wrap {
        //&::after {
        //    opacity: 0;
        //    visibility: hidden;
        //}
    }
    #site-nav {
        top: 0;
        transform: translate(0, -110%);
    }
}

html.js-nav {
    #site-wrap {
        //&::after {
        //    opacity: 1;
        //    visibility: visible;
        //}
    }
    #header  {
        box-shadow: none;
        height: 100%;
        overflow-y: auto;
        transform: translate(0, 0);
    }
    #site-nav {
        backdrop-filter: blur(1rem);
        left: 0;
        opacity: 1;
        transform: translate(0, 0);
        @include bp-max($bp__1200) {
            position: absolute;
            top: 0;
        }
    }
}

// contains utility-nav and nav-block
#site-nav {
    align-items: stretch;
    backdrop-filter: blur(0);
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    min-height: 100vh;
    justify-content: flex-start;
    left: 0;
    opacity: 0;
    padding: 5rem 0 1.75rem; //80px 0 0 28px
    position: absolute;
    top: 0;
    transition-duration: 0.3s;
    transition-property: backdrop-filter, opacity;
    transition-timing-function: ease;
    width: 100%;
    min-width: 375px;
    z-index: 1;
    .preload * {
        transform: translate(0, -110%);
    }
}


// contains primary nav menu
.nav-block {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 auto;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 2.9375rem 0 0; //47px 0 0
    position: relative;
    width: 100%;
    @include bp($bp__800) {
        padding: 3.875rem 0 0; //62px 0 0
    }
}

// Primary nav menu
ul.main-menu,
ul.utility-menu {
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    flex: 0 1 auto;
    justify-content: flex-start;
    margin: 0 0 0.8125rem; //0 0 13px
    width: 100%;
    li {
        &.submenu-open {
            > a {
                > span {
                    background-color: rgba($color__theme_blue, 1);
                }
                + .submenu-toggle {
                    .fa {
                        transform: rotateZ(-180deg);

                    }
                }
                ~ .sub-menu {
                    height: auto;
                    opacity: 1;
                    position: relative;
                    visibility: visible;
                }
            }
        }
        &.inactive {
            > a {
                pointer-events: none;
            }
        }
    }
    a {
        align-items: center;
        border-width: 0;
        color: $color__link_main_nav;
        display: flex;
        flex: 0 1 auto;
        flex-flow: row wrap;
        @include font($font__main, $semibold);
        @include font-size(18);
        justify-content: flex-end;
        letter-spacing: 0.04273em;
        line-height: 1.33334; //24px
        outline: none;
        position: relative;
        text-align: right;
        text-decoration: none;
        text-transform: uppercase;
        transition-duration: 0.2s;
        transition-property: background-size, color, opacity, transform;
        transition-timing-function: ease;
        width: auto;
        @include bp($bp__600) {
            @include font-size(22);
            line-height: normal;
        }
        &:hover,
        &:active,
        &:focus,
        &:focus-within {
            color: $color__link_main_nav_hover;
            text-decoration: none;
        }
    }
    > li {
        align-items: flex-end;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        position: relative;
        transition-property: padding;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        width: 100%;
        &:hover,
        &:active,
        &:focus,
        &:focus-within {
            > a {
                > span {
                    &::before {
                        transform: scale(1);
                    }
                }
            }
        }
        &.current-menu-ancestor,
        &.current-menu-item {
            > a {
                > span {
                }
                + .submenu-toggle {
                }
            }
        }
        &.nonclickable {
            > a:first-of-type {
                cursor: default;
            }
        }
        > a {
            padding: 1.1875rem 1.25rem; //19px 20px
            @include bp(375) {
                padding: 1.1875rem 5.334vw ; //19px (20px/375px + 20px)
            }
            @include bp($bp__600) {
                padding: 1.8125rem 5rem; //29px 80px
            }
            &:hover,
            &:active,
            &:focus {
                > span {
                    &::before {
                        transform: scale(1);
                    }
                }
            }

            > span {
                position: relative;
                &::before {
                    background-color: rgba(#fff, 0.4);
                    content: "";
                    display: inline-block;
                    height: 0.063rem; //1px
                    left: 0;
                    margin: auto 1.25rem auto 0; //auto 20px auto 0
                    position: relative;
                    top: -0.375rem; //-6px
                    transform: scale(0);
                    transform-origin: left center;
                    transition-duration: 0.3s;
                    transition-property: transform;
                    transition-timing-function: ease;
                    width: 3.75rem; //60px
                }
            }
            ~ .submenu-toggle {
            }
        }
    }
    .submenu-toggle {
        appearance: none !important;
        background-color: transparent;
        border-color: transparent;
        border-radius: 0;
        border-width: 0;
        bottom: 0;
        content: "\f107";
        cursor: pointer;
        display: block;
        height: 100%;
        max-height: 3.75rem; //60px
        padding: 0 1.25rem; //0 20px
        position: absolute;
        right: 0.75rem; //12px
        top: 0;
        transition-duration: 0.2s;
        transition-property: color, transform;
        transition-timing-function: ease;
        @include bp(375) {
            padding: 0 5.334vw; //0 20px/375px
        }
        @include bp(375) {
            padding: 0 5.556vw; //0 80px/1440px
        }
        .fa {
            display: block;
            @include font-size(12);
            transform: rotateZ(0deg);
            transform-origin: center center;
            transition-duration: 0.2s;
            transition-property: color, transform;
            transition-timing-function: ease;
        }
    }
    // sub nav
    ul.sub-menu {
        //background-color: $color__theme_blue;
        height: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        transition-property: opacity;
        visibility: hidden;
        width: 100%;
        li {
            position: relative;
            width: 100%;
            &:hover,
            &:active,
            &:focus,
            &.submenu-open {
                > a {
                    color: $color__link_main_nav_hover;
                    &::before {
                        opacity: 1;
                    }
                    > span {
                        animation: gradient-text-animation 0.2s ease-in forwards;
                        transform: translate3d(0, 0, 0);
                    }
                    + .submenu-toggle {
                        color: $color__link_main_nav_hover;
                        .fa {

                        }
                    }
                }
            }
            &.current-menu-ancestor,
            &.current-menu-item {
                > a {
                    color: $color__link_main_nav_hover;
                    &::before {
                        opacity: 1;
                    }
                    > span {
                        animation: gradient-text-animation 0.2s ease-in forwards;
                        transform: translate3d(0, 0, 0);
                    }
                }
            }
            &.current-menu-ancestor {
                > a {
                    + .submenu-toggle {
                        color: $color__link_main_nav_hover;
                    }
                }
            }
            &.current-menu-item {
                &:hover,
                &:active,
                &:focus {
                    > a {
                        + .submenu-toggle {
                            color: $color__link_main_nav_hover;
                        }
                    }
                }
                > a {
                    color: $color__link_main_nav_hover;
                    + .submenu-toggle {
                        color: $color__link_main_nav;
                    }
                }
            }
        }
        a {
            @include font-size(16);
            padding: 1rem 2rem; //16px 32px
            white-space: normal;
            @include bp(375) {
                padding: 1rem calc(5.334vw + 1.375rem); //16px (20px/375px + 22px)
            }
            @include bp($bp__1000) {
                padding: 1rem calc(5.556vw + 1.375rem); //16px (80px/1440px + 22px)
            }
            &::before {
                color: $color__link_main_nav_hover;
                content: "\e902"; //arrow-right
                display: inline;
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'icomoon' !important;
                @include font-size(12);
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                line-height: 1;
                margin: 0;
                opacity: 0;
                text-transform: none;
                transition-duration: 0.2s;
                transition-property: color, opacity, transform;
                transition-timing-function: linear;
                width: 1.25rem;
            }
            &:hover,
            &:active,
            &:focus {
                color: $color__link_main_nav_hover;
                &::before {
                    opacity: 1;
                }
                > span {
                    animation: gradient-text-animation 0.2s ease-in forwards;
                    transform: translate3d(0, 0, 0);
                }
            }
            > span {
                animation: gradient-text-animation-rev 0.2s ease-in forwards;
                background-image: linear-gradient(269deg, #000 0, #000 49.9%, #0c54a6 50%, #5bbbe4 76%, #1485c7 100%);
                background-repeat: no-repeat;
                background-size: 200%;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-text-fill-color: transparent;
                position: relative;
                transform: translate3d(-1.25rem, 0, 0);
                transition-duration: 0.2s;
                transition-property: background-position, color, opacity, transform;
                transition-timing-function: ease-in;
            }
            @keyframes gradient-text-animation {
                0% {
                    background-position: 100%;
                }
                80% {
                    background-position: 50%;
                }
                100% {
                    background-position: 0;
                }
            }
            @keyframes gradient-text-animation-rev {
                0% {
                    background-position: 0;
                }
                80% {
                    background-position: 50%;
                }
                100% {
                    background-position: 100%;
                }
            }
        }
        .submenu-toggle {
            max-height: 2.875rem; //46px
        }
        ul.sub-menu {
            margin: 0;
            &:hover,
            &:active,
            &:focus {
                ~ a {
                    color: $color__link_main_nav_hover;
                }
            }
            li {
                position: relative;
                width: 100%;
                &.current-menu-ancestor {
                    > a {
                        + .submenu-toggle {
                            color: $color__link_main_nav_hover;
                        }
                    }
                }
                &.current-menu-item {
                    &:hover,
                    &:active,
                    &:focus {
                        > a {
                            + .submenu-toggle {
                                color: $color__link_main_nav_hover;
                            }
                        }
                    }
                    > a {
                        color: $color__link_main_nav_hover;
                        + .submenu-toggle {
                            color: $color__link_main_nav_hover;
                        }
                    }
                }
            }
            a {
                padding: 0.6875rem 2rem 0.6875rem 2.75rem; //11px 32px 11px 44px
                @include bp(375) {
                    padding: 0.6875rem calc(5.334vw + 1.375rem) 0.6875rem calc(5.334vw + 2.125rem); //11px (20px/375px + 34px)
                }
                @include bp($bp__1000) {
                    padding: 0.6875rem calc(5.556vw + 1.375rem) 0.6875rem calc(5.556vw + 2.125rem); //11px (80px/1440px + 34px)
                }
            }
            ul.sub-menu {
                a {
                    padding: 0.75rem 1.25rem 0.8125rem 3.75rem; //12px 20px 13px 60px
                    @include bp(375) {
                        padding: 0.75rem 5.334vw 0.8125rem calc(5.334vw + 2.5rem); //12px 20px/375px 13px (20px/375px + 40px)
                    }
                    @include bp($bp__1000) {
                        padding: 0.75rem 5.556vw 0.8125rem calc(5.556vw + 2.5rem); //12px 75px/1350px 13px (80px/1440px + 40px)
                    }
                }
            }
        }
    }
}


// Utility Nav
ul.utility-menu {
    padding: 0 0 3.75rem; //0 0 60px
    @include bp($bp__600) {
        margin: auto 0 0;
        padding: 0 0 1.5rem; //0 0 24px
    }
    > li {
        > a {
            color: #fff;
            @include font-size(14);
            padding-bottom: 0.75rem; //12px
            padding-top: 0.75rem; //12px
            &:hover,
            &:active,
            &:focus {
            }
            &[class*="external"] {
                &::after {
                    color: $color__theme_blue;
                    margin: 0 0 0.25rem 0.625rem; //0 0 4px 10px
                }
            }
        }
    }
}


// Mobile Nav Footer {
.nav-footer {
    margin: auto 0 0;
    position: relative;
    width: 100%;
    @include bp($bp__600) {
        display: none;
    }
    > .content-container {
        align-items: flex-start;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        position: relative;
        width: 100%;
    }
    .share-link,
    .lang-link {
        flex: 0 1 auto;
    }
}