// Social & sharing icons

// values from /abstracts/_variables.scss
// Default social links
.social-links {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    li {
        flex: 0 1 auto;
        a {
            align-items: center;
            color: $color__social;
            display: flex;
            flex-flow: row wrap;
            height: 2.5rem; //40px
            justify-content: center;
            letter-spacing: normal;
            line-height: 2.5rem; //40px
            position: relative;
            text-align: center;
            text-decoration: none;
            width: 2.5rem; //40px
            &:hover,
            &:active,
            &:focus {
                color: $color__link_hover;
                &.facebook {
                    color: $color__facebook;
                }
                &.instagram {
                    color: $color__instagram;
                }
                &.linkedin {
                    color: $color__linkedin;
                }
                &.pinterest {
                    color: $color__pinterest;
                }
                &.snapchat {
                    color: $color__snapchat;
                }
                &.twitter {
                    color: $color__twitter;
                }
                &.vimeo {
                    color: $color__vimeo;
                }
                &.youtube {
                    color: $color__youtube;
                }
            }
            &.external {
                &::after {
                    display: none;
                }
            }
        }
        [class*=" fa-"] {
            font-family: 'icomoon' !important;
            @include font-size(17);
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            line-height: inherit;
            speak: none;
            text-align: center;
            text-transform: none;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            width: 100%;
        }
    }
}


.social-share-list.addthis_toolbox {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    > li {
        flex: 0 1 auto;
        &:last-of-type {
            a {
                margin: 0;
            }
        }
    }
    a {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        height: 2.25rem; //36px
        margin: 0;
        padding: 0 0.625rem; //0 10px
        text-decoration: none;
        &.addthis_button_compact {
            border-radius: 0;
            border-width: 0;
            height: auto;
            padding: 0;
            width: auto;
            span {
                color: $color__text_heading;
                flex: 0 1 auto;
                @include font($font__main, $semibold);
                @include font-size(16);
                line-height: 1;
                text-transform: uppercase;
                transition-duration: 0.2s;
                transition-property: color, text-decoration-color, transform;
                transition-timing-function: ease;
            }
            i.fa {
                color: $color__link;
                flex: 0 1 auto;
                @include font-size(16);
                margin: 0 0 0 0.75rem; //0 0 0 12px
                width: auto;
            }
            &:hover,
            &:active,
            &:focus {
                span {
                    color: $color__link_hover;
                }
                i.fa {
                    color: $color__link_hover;
                }
            }
        }
        &.at300b {
            width: 2.25rem; //36px
        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            &.facebook {
                color: $color__facebook;
                border-color: rgba($color__facebook, 1);
            }
            &.instagram {
                color: $color__instagram;
                border-color: rgba($color__instagram, 1);
            }
            &.linkedin {
                color: $color__linkedin;
                border-color: rgba($color__linkedin, 1);
            }
            &.pinterest {
                color: $color__pinterest;
                border-color: rgba($color__pinterest, 1);
            }
            &.snapchat {
                color: $color__snapchat;
                border-color: rgba($color__snapchat, 1);
            }
            &.twitter {
                color: $color__twitter;
                border-color: rgba($color__twitter, 1);
            }
            &.vimeo {
                color: $color__vimeo;
                border-color: rgba($color__vimeo, 1);
            }
            &.youtube {
                color: $color__youtube;
                border-color: rgba($color__youtube, 1);
            }
        }
        i.fa {
            @include font-size(18);
            text-align: center;
            vertical-align: text-bottom;
            width: 100%;
        }
    }
}
